<article class="content items-list-page">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Bancos</li>
            <li class="breadcrumb-item"><a [routerLink]="['/banks/transfers']" routerLinkActive="router-link-active">Transferencias</a></li>
        </ol>
    </nav>

    <div class="title-search-block">
        <div class="title-block">
            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3 class="title">Detalle <small *ngIf="!isLoadingData">( {{ jsonArray.length }} )</small></h3>
                </div>
            </div>
        </div>
    </div>

    <app-not-data *ngIf="!isLoadingData && jsonArray.length === 0"></app-not-data>

    <app-loading-data *ngIf="isLoadingData"></app-loading-data>

    <div class="card items animated fadeIn fast">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h4 class="title">Bloque {{ jsonArray[0]?.block }} Fecha {{ jsonArray[0]?.acceptanceDate | date: 'dd/MM/yyyy' }} Import {{grandTotal|currency}}</h4>
                </div>
            </div>
        </div>

        <ul class="item-list striped" *ngIf="!isLoadingData && jsonArray.length > 0">
            <li class="item item-list-header">
                <div class="row">
                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>RFC</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Nombre</span>
                        </div>
                    </div>

                    <div class="item-col item-col-header item-col-item">
                      <div class="no-overflow">
                          <span>Tipo</span>
                      </div>
                  </div>

                    <div class="item-col item-col-header item-col-item">
                        <div class="no-overflow">
                            <span>Importe</span>
                        </div>
                    </div>
                </div>
            </li>

            <li class="item" *ngFor="let element of jsonArray | angularPaginator: {itemsPerPage: this.itemsPerPage, currentPage: currentPage }; let i = index">
                <div class="item-row">
                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">RFC</div>
                        <div>
                            <span>{{ element.rfc }}</span>
                        </div>
                    </div>

                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Nombre</div>
                        <div>
                            <span>{{ element.name }}</span>
                        </div>
                    </div>

                    <div class="item-col fixed pull-left item-col-title">
                      <div class="item-heading">Nombre</div>
                      <div>
                          <span>{{ element.type }}</span>
                      </div>
                  </div>

                    <div class="item-col fixed pull-left item-col-title">
                        <div class="item-heading">Importe</div>
                        <div class="text-right">
                            <span>{{ element.amount | currency }}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <app-angular-paginator *ngIf="!isLoadingData && jsonArray.length > 0" className="pagination-sm" forceEllipses="true" (pageChange)="currentPage = $event"></app-angular-paginator>

    <div class="form-group ">
        <a class="btn btn-outline-info " [routerLink]="['/banks/transfers'] "><i class="fas fa-arrow-left "></i> Regresar</a>
    </div>
</article>
