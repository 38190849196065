import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../account/login/services/account.service';
import { LoanInterface } from './interfaces/loan.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanService } from './services/loan.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { PaymentInterface } from '../payments/interfaces/payment.interface';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';

@Component({
  selector: 'app-loan-detais',
  templateUrl: './loan-detais.component.html',
  styles: [
  ]
})
export class LoanDetaisComponent implements OnInit {
  LoadingData = false;
  returnPath = '/administration/loans';
  searchValue = '';
  message = '';
  deposit = 0;
  applications: ApplicationInterface[];
  loan: LoanInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    bankId: '00000000-0000-0000-0000-000000000000',
    teacherStatusId: '00000000-0000-0000-0000-000000000000',
    date: new Date(),
    active: true,
    drawId: '',
    teacherId: '',
    loanParameterId: '',
   prefix: '',
    individualAccount: '',
    checkNumber: '0',
    amount: 0,
    discount: 0,
    interests: 0,
    comment: '',
    loanNumber: '',
    fortnightStart: '',
    fortnightEnd: '',
    status: 'Activo',
    openingBonus: 0,
    remainingFortnightly: 0,
    warranty: 0,
    applied: false,
    savingsPending: false,
    haveFinancialLiquidity: false,
    account: '',
    movementType: '',
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    draw: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: true,
      name: 'N/A',
      openDate: new Date(),
      closeDate: new Date(),
      status: '',
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      loans: []
    },
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      email: '',
      admission: '',
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      lastStatus: 'Activo',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      applied: true,
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      document: 0,
      documentNumber: '',
      NumberOfLoans: 0,
      itIsSupplier: false,
      supports: [],
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      lowStaffing: []
    },
    region: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date,
      active: true,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: 'N/A'
    },
    loanParameter: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      active: true,
      amount: 0,
      discount: 0,
      prefix: '',
      interests: 0,
      totalPerceptions: 0,
      saving: 0,
      guaranteedPercentage: 0,
      openingBonus: 0,
      fortnightId: '00000000-0000-0000-0000-000000000000',
      fortnight: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: true,
        numberFortnight: 0
      },
      fortnightCapital: 0,
      fortnightIVA: 0,
      fortnightInterest: 0,
      fortnightLifeInsurance: 0,
      iva: 0,
      lifeInsurance: 0,
      type: '',
      teachers: '',
      accountingConcept: ''
    },
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: 'Prueba',
      code: '00'
    },
    teacherStatus: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date,
      active: true,
      name: 'Activo',
      percentageSaving: 0,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000'
    },
    acceptanceDate: new Date(),
    protectionPremium: 0,
    block: 0,
    questions: [],
    payments: [],
    settlementsTotal: 0,
    settlementsSavingTotal: 0,
    settlementsCapital: 0,
    settlementsInterest: 0,
    settlementsLifeInsurance: 0,
    settlementsLifeCondoneLifeInsurance: 0,
    settlementsCondoneInterest: 0,
    settlements: [],
    settlementsSaving: []
  };
  loansSettlements: LoanInterface[];

  constructor(
    public accountService: AccountService,
    private router: Router,
    private loanService: LoanService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.LoadingData = true;
    this.accountService.getApplications('administration', 'loans')
    .subscribe(applications => {
      this.applications = applications;
      this.activatedRoute.params.subscribe(params => {
        if ( params['returnPath'] ) {
          this.returnPath = params['returnPath'];
        }

        if(params['searchValue']) {
          this.searchValue = params['searchValue'];
        }

        if(params['pending']) {
          this.returnPath = this.returnPath + '/'+ params['pending'];
        }

        if (params['id']) {
          this.loanService.getById(params['id'])
          .subscribe(loan => {
            this.loan = loan;
            this.deposit = loan.loanParameter.amount - (loan.settlementsCapital + loan.settlementsInterest + loan.settlementsLifeInsurance + loan.loanParameter.openingBonus + loan.settlementsSavingTotal + (loan.loanParameter.amount * (loan.loanParameter.guaranteedPercentage / 100)));
            this.loansSettlements = this.getSettlements(this.loan.settlements);
            this.loansSettlements.forEach(loan => {
              loan.payments = this.loan.settlements.filter(x => x.loanId === loan.id);
            });
            this.LoadingData = false;
          }, error => this.helperService.setErrorMessage(error));
        }
      });
    }, error => this.helperService.setErrorMessage(error));
  }

  getSettlements(payments: PaymentInterface[]): LoanInterface[] {
    let arrRetrun: LoanInterface[] = [];
    payments.forEach(payment => {
      if(!arrRetrun.some(x => x.id)) {
        const loan = payment.loan;
        loan.settlementsCapital = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.capital, 0);
        loan.settlementsInterest = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.interest, 0);
        loan.settlementsCondoneInterest = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.condoneInterest, 0);
        loan.settlementsLifeInsurance = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.lifeInsurance, 0);
        loan.settlementsLifeCondoneLifeInsurance = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.condoneLifeInsurance, 0);
        arrRetrun.push(loan);
      }
    });
    return arrRetrun;
  }

  showConfirmValidation(): void {
    this.notificationService.showConfirm(`¿Seguro de que la información del prestamo ${ this.loan.loanNumber } esta correcta?`)
    .then((result: any) => {
      if(result.isConfirmed) {
        this.loanService.validated(this.loan.id)
        .subscribe( loan => {
          this.loan.status = loan.status;
          this.router.navigate([this.returnPath]);
        }, error => this.helperService.setErrorMessage(error));
      }
    });
  }

}
