import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { SelectOption } from '../interfaces/select-option.interface';
import { InputTypes } from '../enums/sweetalert.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  showErrorMessage(message: string = 'Something went wrong!'): void {
    Swal.fire({
      icon: 'error',
      title: message,
      confirmButtonText: 'Aceptar'
    });
  }

  showInfoMessage(message: string = 'Something went wrong!'): void {
    Swal.fire({
      icon: 'info',
      title: message,
      confirmButtonText: 'Aceptar'
    });
  }

  showConfirm(message: string = 'Something went wrong!', title: string = 'Alerta'): any {
    return Swal.fire({
      icon: 'warning',
      title: title,
      text: message,
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: `Cancelar`
    });
  }

  async showConfirmDate(title: string = 'Fecha de aplicación') {
    const { value: formValues } = await Swal.fire({
      title: title,
      icon: 'info',
      html: '<input id="datetimepicker" type="date" class="form-control" autofocus>',
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: `Cancelar`,
      preConfirm: () => {
        const input = document.getElementById('datetimepicker') as HTMLInputElement | null;
        return input.value;
      }
    });
    return formValues;
  }

  async showConfirmInput(message: string, title: string = '', inputType: InputTypes = InputTypes.Text) {
    const { value: response } = await Swal.fire({
      icon: 'warning',
      title: title,
      text: message,
      confirmButtonText: 'Aceptar',
      input: 'text',
      showDenyButton: true,
      denyButtonText: `Cancelar`,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if(value === '') {
            resolve('Debes de ingresar un importe.')
          } else if(parseFloat(value) < 0) {
            resolve('El importe debe ser mayor a 0.')
          } else {
            resolve();
          }
        })
      }
    })
    if(response === false || response === undefined)
    {
      return null;
    }
    return response;
  }

  async showConfirmSelect(message: string, options: SelectOption[], title: string = 'Alerta', placeHolder: string = 'Seleccione una opción', requiredMessage: string = 'Debes de seleccionar una opción.') {
    let inputOptions = new Map();
    options.forEach(option => {
      inputOptions.set(option.value, option.text);
    });
    const { value: response } = await Swal.fire({
      icon: 'warning',
      title: title,
      text: message,
      input: 'select',
      confirmButtonText: 'Aceptar',
      showDenyButton: true,
      denyButtonText: `Cancelar`,
      inputOptions: inputOptions,
      inputPlaceholder: placeHolder,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if(value === '') {
            resolve(requiredMessage)
          } else {
            resolve();
          }
        })
      }
    })

    if(response === false || response === undefined)
    {
      return null;
    }
    return response;
  }

  async showConfirmChangeContribution(title: string, optionsPercentage: string, optionsFortnights: string, optionsChange: string) {
    const { value: response } = await Swal.fire({
      icon: 'question',
      title: title,
      confirmButtonText: 'Aceptar',
      showDenyButton: true,
      denyButtonText: `Cancelar`,
      html: '<div class="row">' +
      '<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">' +
      '<label class="control-label">Aportación</label><select class="form-control" id="ddlPercentage">' + optionsPercentage + '</select>' +
      '</div>' +
      '<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">' +
      '<label class="control-label">Quincena</label><select class="form-control" id="ddlFortnight">' + optionsFortnights + '</select>' +
      '</div>' +
      '<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">' +
      '<label class="control-label">Cambio</label><select class="form-control" id="ddlChange">' + optionsChange + '</select>' +
      '</div>' +
      '</div>',
      focusConfirm: false,
      preConfirm: () => {
        const percentage = document.getElementById('ddlPercentage') as HTMLInputElement | null;
        const fortnight = document.getElementById('ddlFortnight') as HTMLInputElement | null;
        const change = document.getElementById('ddlChange') as HTMLInputElement | null;
        if(percentage.value === '' || fortnight.value === '' || change.value === '' ) {
          return undefined;
        }
        return [
          percentage.value,
          fortnight.value,
          change.value,
        ]
      }
    })

    if(response === false || response === undefined || response === true)
    {
      return null;
    }
    return response;
  }

  async showConfirmAuthentication(title: string) {
    const { value: response } = await Swal.fire({
      icon: 'question',
      text: title,
      confirmButtonText: 'Aceptar',
      showDenyButton: true,
      denyButtonText: `Cancelar`,
      html: '<label class="control-label">Usuario</label><input type="text" class="form-control" id="txtUser">' +
      '<label class="control-label">Contraseña</label><input type="password" class="form-control" id="txtPassword">',
      focusConfirm: false,
      preConfirm: () => {
        const user = document.getElementById('txtUser') as HTMLInputElement | null;
        const password = document.getElementById('txtPassword') as HTMLInputElement | null;
        if(user.value === '' || password.value === '') {
          return undefined;
        }
        return {
          username: user.value,
          password: password.value
        }
      }
    })

    if(response === false || response === undefined || response === true)
    {
      return null;
    }
    return response;
  }

  async showConfirmBankData(title: string, email: string, options: string, account: string) {
    const { value: response } = await Swal.fire({
      text: title,
      confirmButtonText: 'Aceptar',
      showDenyButton: true,
      denyButtonText: `Cancelar`,
      html: `<label class="control-label">Email</label><input type="text" class="form-control" id="txtEmail" value="${email}" />` +
      `<label class="control-label">Banco</label><select class="form-control" id="ddlBank">${options}</select>` +
      `<label class="control-label">Cuenta</label><input type="text" class="form-control" id="txtAccount" value="${account}" >`,
      focusConfirm: false,
      preConfirm: () => {
        const email = document.getElementById('txtEmail') as HTMLInputElement | null;
        const bank = document.getElementById('ddlBank') as HTMLInputElement | null;
        const account = document.getElementById('txtAccount') as HTMLInputElement | null;

        if(email.value === '' || bank.value === '' || account.value === '') {
          return undefined;
        }
        return {
          email: email.value,
          bank: bank.value,
          account: account.value
        }
      }
    })

    if(response === false || response === undefined || response === true)
    {
      return null;
    }
    return response;
  }

  toastConfirm(message: string): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    });

    Toast.fire({
      icon: 'success',
      title: message
    });
  }

  toastInfo(message: string): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    Toast.fire({
      icon: 'error',
      title: message
    });
  }


}
