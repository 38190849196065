import { Component, OnInit } from '@angular/core';
import { MoneyOutPolicyService } from './services/money-out-policy.service';
import { PeriodInterface } from '../../../interfaces/period.interface';
import { ExcelService } from '../../../services/excel.service';

@Component({
  selector: 'app-money-out',
  templateUrl: './money-out.component.html',
  styles: []
})

export class MoneyOutComponent implements OnInit {
  dataReport: any[] = [];
  inicio = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  fin = `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`;
  period: PeriodInterface = {
    start: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`,
    end: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`
  };
  errorMessage = '';
  loadingData = false;
  balancePositive = true;

  constructor( private moneyOutService: MoneyOutPolicyService,
    private excelService: ExcelService ) { }

  ngOnInit() {
    this.loadingData = true;
    this.loadData();
  }

  loadData() {
    this.errorMessage = '';
    if ( this.isValidDate(this.inicio) && this.inicio.length === 10 ) {
      if ( this.isValidDate(this.fin) && this.inicio.length === 10 ) {
        this.period.start = this.inicio;
        this.period.end = this.fin;
        this.moneyOutService.getData( this.period )
          .subscribe( (data) => {
            this.dataReport = data;
            this.loadingData = false;
          }, error => this.setErrorMessage(error));
      } else {
        this.errorMessage = 'La fecha de fin no cuenta con el formato correcto.';
      }
    } else {
      this.errorMessage = 'La fecha de inicio no cuenta con el formato correcto.';
    }
    this.loadingData = false;
  }

  setErrorMessage( error ) {
    console.log(error);
    if (error && error.error) {
      this.errorMessage = error.error;
      this.loadingData = false;
    }
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  exportData() {
    this.excelService.exportAsExcelFileWithoutHeader(this.CreatePolicy(),
      `Egresos_${ this.inicio }_${ this.fin }`);
  }

  CreatePolicy(): any {
    const array = [];
    this.dataReport.forEach(loan => {
      this.addEmptyRows(array, 2);
      let debit = loan.settlementsCapital + parseFloat(loan.settlementsInterest) + parseFloat(loan.settlementsLifeInsurance);
      //        PRESTAMO
      array.push(this.createItem(
        '1150-001-000',
        `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
        loan.loanParameter.amount.toString(),
        ''
      ));
      array.push(this.createItem(
        '4100-002-001',
        `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
        '',
        loan.loanParameter.openingBonus.toString()
      ));
      array.push(this.createItem(
        '4100-002-002',
        `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
        '',
        loan.warranty.toString()
      ));
      array.push(this.createItem(
        '1160-002-000',
        `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
        loan.realInterest + loan.realIVA,
        ''
      ));
      array.push(this.createItem(
        '2140-001-000',
        `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
        '',
        loan.realInterest + loan.realIVA
      ));
      array.push(this.createItem(
        '1160-001-000',
        `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
        loan.realLifeInsurance.toString(),
        ''
      ));
      array.push(this.createItem(
        '2140-002-000',
        `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
        '',
        loan.realLifeInsurance.toString()
      ));
      array.push(this.createItem(
        '1120-005-000',
        `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
        '',
        loan.deposit.toString()
      ));
      if(debit > 0) {
        array.push(this.createItem(
          '1120-005-000',
          `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
          '',
          debit.toString()
        ));
      }
      if(loan.settlementsSavingTotal > 0) {
        array.push(this.createItem(
          '1120-005-000',
          `${loan.movementType.slice(0, 3).toUpperCase() }-${loan.checkNumber} ${loan.loanNumber} PRESTAMOS ${loan.teacher.name.toUpperCase()}`,
          '',
          loan.settlementsSavingTotal.toString()
        ));
      }
      array.push(this.createItem(
        '1120-007-000 ',
        `BANORTE DEPOSITOS 1087275480 LIQUIDACION PRESTAMO`,
        debit + loan.settlementsSavingTotal,
        ''
      ));
      if(loan.settlementsCapital > 0) {
        array.push(this.createItem(
          '1150-001-000',
          `CREDITOS ORDINARIOS Y EXTRAORDINARIOS / ${loan.teacher.name.toUpperCase()}`,
          '',
          loan.settlementsCapital.toString()
        ));
      }
      if(loan.settlementsInterest > 0) {
        array.push(this.createItem(
          '4100-001-000',
          `INTERESES GANADOS POR PRESTAMOS`,
          '',
          loan.settlementsInterest.toString()
        ));
      }
      if(loan.settlementsInterest + loan.settlementsCondoneInterest > 0) {
        array.push(this.createItem(
          '2140-001-000',
          `INTERESES SOBRE PRESTAMOS / ${loan.teacher.name.toUpperCase()}`,
          (loan.settlementsInterest + loan.settlementsCondoneInterest).toString(),
          ''
        ));
        array.push(this.createItem(
          '1160-002-000',
          `INTERESES POR COBRAR A PRESTAMOS DE / ${loan.teacher.name.toUpperCase()}`,
          '',
          (loan.settlementsInterest + loan.settlementsCondoneInterest).toString()
        ));
      }

      if(loan.settlementsLifeInsurance > 0 && loan.settlementsLifeCondoneLifeInsurance > 0) {
        if(loan.settlementsLifeInsurance > 0) {
          array.push(this.createItem(
            '4100-002-003',
            `PRIMA DE PROTECCION AL AHORRO`,
            '',
            loan.settlementsLifeInsurance.toString()
          ));
        }
        array.push(this.createItem(
          '2140-002-000',
          `PRIMA. PRTEC AL AHORRO / ${loan.teacher.name.toUpperCase()}`,
          loan.settlementsLifeInsurance.toString(),
          ''
        ));
        array.push(this.createItem(
          '1160-001-000',
          `PRIMA. PRTEC AL AHORRO / ${loan.teacher.name.toUpperCase()}`,
          '',
          loan.settlementsLifeInsurance.toString()
        ));
      }

      if(loan.settlementsSavingTotal > 0) {
        array.push(this.createItem(
          '2110-001-000',
          `AHORRO DE LOS MAESTROS /PARA ACOMPLETAR AHORRO MINIMO REQUERIDO PRESTAMO`,
          '',
          loan.settlementsSavingTotal.toString()
        ));
      }
    });
    return array;
  }

  createItem(account: string, description: string, abono: string, cargo: string ): any {
    return {
      account,
      description,
      abono: (abono) ? parseFloat(abono).toFixed(2) : '',
      cargo: (cargo) ? parseFloat(cargo).toFixed(2) : ''
    };
  }

  addEmptyRows(array: any, count: number): any {
    for (let index = 0; index < count; index++) {
      array.push(this.createItem('','','',''));
    }
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

}
