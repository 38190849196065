import { Component, OnInit } from '@angular/core';
import { LoanService } from './services/loan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoanInterface } from './interfaces/loan.interface';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { AccountService } from '../../account/login/services/account.service';
import { CurrencyPipe } from '@angular/common';
import { PaymentInterface } from '../payments/interfaces/payment.interface';
import { FortnightInfoInterface } from '../../../interfaces/fortnight-info.interface';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { InputTypes } from '../../../../app/enums/sweetalert.enum';

declare let jsPDF;

@Component({
  selector: 'app-loan-list',
  templateUrl: './loan-list.component.html',
  styles: []
})
export class LoanListComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 15;
  maxSize = 5;
  loadingData = false;
  searchValue = '';
  jsonArray: any[] = [];
  statusArr: string[] = [];
  months: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  pending = 0;
  pendingToSign = 0;
  pendingToValidate = false;
  applications: ApplicationInterface[];

  constructor( private activatedRoute: ActivatedRoute, private loanService: LoanService,
    public accountService: AccountService,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.loadingData = true;
    this.accountService.getApplications('administration', 'loans')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          if(params.searchValue){
            this.searchValue = params.searchValue;
          }

          if(params.pending){
            this.pendingToValidate = params.pending;
          }

          this.search();
        });
      }, error => this.helperService.setErrorMessage(error));
  }

  loadData(value: string) {
    this.loanService.get(value, this.pendingToValidate)
    .subscribe(data => {
      this.jsonArray = data;
      this.loadingData = false;
      this.loanService.havePending( )
      .subscribe(pending => {
        this.pending = pending;
        this.loanService.havePendingToSign()
        .subscribe(pending => {
          this.pendingToSign = pending;
          this.loadingData = false;
        }, error => this.helperService.setErrorMessage(error))
      }, error => this.helperService.setErrorMessage(error));
    }, error => this.helperService.setErrorMessage(error));
  }

  search() {
    this.loadingData = true;
    let search = this.searchValue;
    if ( search === '' ) {
      search = 'null';
    }
    this.loadData( search);
  }

  deleteItem(id: string, loan: LoanInterface) {
    loan.status = 'Cancelado';
    if (loan.teacher.colony === '') {
      loan.teacher.colony = 'N/A';
    }
    if (loan.teacher.street === '') {
      loan.teacher.street = 'N/A';
    }
    this.loanService.update(id, loan).subscribe(
      () => {
        this.loadData('null');
        this.notificationService.toastConfirm('Se cancelo correctamente el prestamo');
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  showChangeStatus( loan: LoanInterface ) {
    let status = 'Preliquidado'
    if( loan.status !== 'Activo' ) {
      status = 'Activo'
    }

    this.notificationService.showConfirm(`¿Seguro que desea cambiar el estatus al prestamo ${loan.loanNumber} a ${status}?`)
    .then(result => {
      if(result.isConfirmed) {
        loan.status = status;
        this.changeStatus(loan);
      }
    });
  }

  changeStatus( loan: LoanInterface ): void {
    loan.userIdLastUpdated = this.accountService.getUserId();
    if ( loan.teacher.colony === '' ) {
      loan.teacher.colony = 'N/A';
    }
    if ( loan.teacher.street === '' ) {
      loan.teacher.street = 'N/A';
    }

    this.loanService.update(loan.id, loan)
        .subscribe( () => {
          this.notificationService.toastConfirm('Se cambio el estatus correctamente.');
        }, error => this.helperService.setErrorMessage(error));
  }

  async showConfirm(id: string, numero: string, name: string, loan: LoanInterface) {
    const comment = await this.notificationService.showConfirmInput(`¿Seguro que desea cancelar el prestamo ${ numero } ( ${ name } )?`, 'Cancelación de prestamos', InputTypes.Textarea );
    if(comment !== null) {
      loan.userIdLastUpdated = this.accountService.getUserId();
      loan.comment = `${ loan.comment } CANCELADO: ${ comment }`;
      this.deleteItem(id, loan);
    }
  }

  showConfirmLetters(): void {
    this.notificationService.showConfirm(`¿Seguro que desea generar las ${this.pendingToSign} cartas?`)
    .then((result: any) => {
      if(result.isConfirmed) {
        this.loanService.getPendingToSign()
        .subscribe(loans => {
          this.generateDocument(loans);
          this.pendingToSign = 0;
        },error => this.helperService.setErrorMessage(error))
      }
    });
  }

  generateDocument(loans: LoanInterface[]): void {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4'
    });

    let row = 30;
    doc.setFont('times', 'normal');
    doc.setFontSize(12);
    let contador = 0;
    let ticks = ((new Date().getTime() * 10000) + 621355968000000000);
    loans.forEach(loan => {
      if ( contador > 0 ) {
        doc.addPage();
      }
      doc.setFontStyle('bold');
      doc.text(`Prof. Eduardo Antonio Zendejas Amparan`, 30, row);
      row+=5;
      doc.setFontStyle('normal');
      doc.text(`Secretario General de la sección 8 del SNTE.`, 30, row);
      row+=7;
      doc.setFontSize(11);
      doc.text(`Con la presente, le comunico y hago patente mi autorización para que el Fondo de Capitalización y Ahorro para Apoyo a Prestaciones Sociales (FOCAPS) pueda utilizar los medios digitales para hacer las transferencia electrónicas que sean necesarias a mi cuenta bancaria cuyos datos se proporcionan en este mismo escrito con la finalidad de que los préstamos que se realicen por conducto del FOCAPS sean transmitidos a través de transferencias digitales bancarias.`, 30, row, {
        align: 'justify',
        maxWidth: 150
      });
      row+=30
      doc.text(`A partir de la fecha de esta comunicación, se concede al FOCAPS la facultad de recibir transferencias bancarias destinadas a los préstamos otorgados a mi persona. En este sentido, se permite realizar el depósito del préstamo directamente en mi cuenta personal bancaria mediante transferencia electrónica. Esta autorización tiene como objetivo agilizar y simplificar el proceso de desembolso de fondos, con lo cual me brindaran un servicio con mayor rapidez y comodidad como usuario del FOCAPS.`, 30, row, {
        align: 'justify',
        maxWidth: 150
      });

      row+=30
      doc.text(`Asimismo, mediante esta autorización, se habilita al Fondo para recibir los pagos correspondientes a las obligaciones de pago que tengo con el FOCAPS mediante transferencias bancarias. Con esta medida, busco optimizar el servicio de los fondos recibidos en préstamo, otorgándome así una alternativa conveniente y segura para cumplir con mis responsabilidades financieras con el FOCAPS.`, 30, row, {
        align: 'justify',
        maxWidth: 150
      });

      row+=25
      doc.text(`Es imperativo destacar que, en virtud de esta autorización, solicito se mantenga un registro meticuloso y transparente de todas las transacciones financieras efectuadas entre mi persona como usuario y el FOCAPS. La integridad y exactitud de estos registros son fundamentales para asegurar la confianza y la satisfacción de ambas partes involucradas en estas transacciones.`, 30, row, {
        align: 'justify',
        maxWidth: 150
      });

      row+=20
      doc.text(`Confío en que esta autorización permitirá mejorar la eficiencia y la calidad del servicio que me otorga el FOCAPS como beneficiario. No obstante, es primordial mantener una estricta protección de datos y la salvaguardia de la privacidad financiera de nuestras transacciones como usuario.`, 30, row, {
        align: 'justify',
        maxWidth: 150
      });

      row+=20
      doc.text(`Solicito, por favor, tomar las medidas pertinentes para implementar esta autorización en los procedimientos operativos del Fondo de Capitalización y Ahorro para Apoyo a Prestaciones Sociales (FOCAPS).`, 30, row, {
        align: 'justify',
        maxWidth: 150
      });

      row+=15
      doc.text(`Agradezco de antemano su diligencia y atención en este asunto.`, 30, row);

      row+=7
      doc.text(`Datos bancarios del que suscribe:`, doc.internal.pageSize.getWidth() / 2, row, {
        align: 'center',
        maxWidth: 150
      });

      row+=7
      doc.text(`Cuenta.- ${loan.account}   Banco.- ${loan?.bank?.name.trim()}`, doc.internal.pageSize.getWidth() / 2, row, {
        align: 'center',
        maxWidth: 150
      });

      row+=7
      doc.text(`Atentamente:`, doc.internal.pageSize.getWidth() / 2, row, {
        align: 'center',
        maxWidth: 150
      });

      row+=7

      doc.text(`Chihuahua, Chih., a ${ this.formatDate(new Date().getDate()) } de ${this.months[new Date().getMonth()]} del ${ new Date().getFullYear() }`, doc.internal.pageSize.getWidth() / 2, row, {
        align: 'center'
      });
      row+=7
      doc.setFontStyle('bold');
      doc.text(`${loan.teacher.name.toUpperCase()}`, doc.internal.pageSize.getWidth() / 2, row, {
        align: 'center'
      });
      row = 25;
      contador++;
    });
    doc.save(`Cartas_${ new Date().getFullYear().toString() }${ this.formatDate(new Date().getMonth() + 1) }${ this.formatDate(new Date().getDate()) }_${ticks}.pdf`);
  }

  getFortnightsTable(payment: PaymentInterface): FortnightInfoInterface[] {
    let fortnights: FortnightInfoInterface[] = [];
    const arrayProperties = Object.keys(payment).map(function(key) {
      return [String(key), payment[key]];
    });

    for (let index = 0; index < arrayProperties.length; index++) {
      if ( arrayProperties[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
        if ( arrayProperties[index][1] !== 0 ) {
          fortnights.push({
            year: payment.year,
            fortnight: this.formatDate(arrayProperties[index][0].slice(9, arrayProperties[index][0].length)),
            amount: arrayProperties[index][1]
          });
        }
      }
    }
    return fortnights;
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  onChangePendingToValidate(): void {
    this.search();
  }
}
