import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { PaymentService } from './services/payment.service';
import { AccountService } from '../../account/login/services/account.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentInterface } from './interfaces/payment.interface';
import { LoanService } from '../loans/services/loan.service';
import { PaymentDTOInterface } from './interfaces/paymentDTO.interface';
import { OpenDirective } from '../../modals/open.directive';
import { SavingService } from '../savings/services/saving.service';
import { HelperService } from '../../../services/helper.service';
import { SavingAmountInterface } from '../../../interfaces/saving-amount.interface';
import { NotificationService } from '../../../../app/services/notification.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styles: []
})
export class PaymentComponent implements OnInit {
  forma: UntypedFormGroup;
  // message: string;
  gettingData = false;
  sendingData = false;
  amount = 0;
  amountWithdraw = 0;
  pathReturn = '/administration/loans';
  years: number[] = [];
  amountSavings: SavingAmountInterface = {
    amount: 0,
    amountInterest: 0
  };
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;
  paymentsDTO: Array<PaymentDTOInterface> = [];

  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  payment: PaymentInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    teacherId: '',
    year: new Date().getFullYear(),
    lastUpdated : new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    loanId: '00000000-0000-0000-0000-000000000000',
    fortnight1: 0,
    fortnight2: 0,
    fortnight3: 0,
    fortnight4: 0,
    fortnight5: 0,
    fortnight6: 0,
    fortnight7: 0,
    fortnight8: 0,
    fortnight9: 0,
    fortnight10: 0,
    fortnight11: 0,
    fortnight12: 0,
    fortnight13: 0,
    fortnight14: 0,
    fortnight15: 0,
    fortnight16: 0,
    fortnight17: 0,
    fortnight18: 0,
    fortnight19: 0,
    fortnight20: 0,
    fortnight21: 0,
    fortnight22: 0,
    fortnight23: 0,
    fortnight24: 0,
    type: 'Abono',
    amount: 0,
    amountSaving: 0,
    condoneInterest: 0,
    financialProducts: 0,
    lifeInsurance: 0,
    condoneLifeInsurance: 0,
    capital: 0,
    wayToPay: '',
    interest: 0,
    folio: '',
    movementDate : `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-
      ${ this.formatDate(new Date().getDate()) }`,
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      lastUpdated: new Date(),
      userIdLastUpdated: this.accountService.getUserId(),
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      lastStatus: 'Activo',
      email: '',
      admission: '',
      applied: true,
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      NumberOfLoans: 0,
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      document: 0,
      documentNumber: '',
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      supports: [],
      lowStaffing: [],
      itIsSupplier: false
    },
    loan:  {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      regionId: '00000000-0000-0000-0000-000000000000',
      bankId: '00000000-0000-0000-0000-000000000000',
      teacherStatusId: '00000000-0000-0000-0000-000000000000',
      prefix: '',
      date: new Date(),
      active: true,
      drawId: '',
      teacherId: '',
      loanParameterId: '',
      individualAccount: '',
      checkNumber: '0',
      amount: 0,
      discount: 0,
      interests: 0,
      comment: '',
      loanNumber: '',
      fortnightStart: '',
      fortnightEnd: '',
      status: '',
      openingBonus: 0,
      remainingFortnightly: 0,
      warranty: 0,
      applied: false,
      haveFinancialLiquidity: false,
      savingsPending: false,
      account: '',
      movementType: '',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      draw: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: true,
        name: 'N/A',
        openDate: new Date(),
        closeDate: new Date(),
        status: '',
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        loans: []
      },
      teacher: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: this.accountService.getUserId(),
        date: new Date(),
        active: true,
        regionId: '00000000-0000-0000-0000-000000000000',
        rfc: '',
        name: '',
        street: '',
        number: '',
        colony: '',
        zipCode: 0,
        locality: '',
        email: '',
        admission: '',
        applied: true,
        birthdate: '',
        contributionId: '00000000-0000-0000-0000-000000000000',
        gender: '',
        lastStatus: 'Activo',
        lastUpdated: new Date,
        userIdLastUpdated: this.accountService.getUserId(),
        identifier: 0,
        amountSavings: 0,
        credit: 0,
        type: '',
        fortnight: '',
        clabe: '',
        curp: '',
        bankId: '00000000-0000-0000-0000-000000000000',
        member: false,
        amountSavingsFortnight: 0,
        document: 0,
        documentNumber: '',
        NumberOfLoans: 0,
        bank: {
          id: '00000000-0000-0000-0000-000000000000',
          userId: '00000000-0000-0000-0000-000000000000',
          date: new Date(),
          active: false,
          lastUpdated: new Date(),
          userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
          name: '',
          code: ''
        },
        contribution: {},
        teacherStatus: [],
        beneficiaries: [],
        teacherPhones: [],
        contributionPercentages: [],
        loans: [],
        savings: [],
        financialliquidity: [],
        payments: [],
        supports: [],
        lowStaffing: [],
        itIsSupplier: false
      },
      region: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date,
        active: true,
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: 'N/A'
      },
      loanParameter: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        active: true,
        amount: 0,
        prefix: '',
        discount: 0,
        interests: 0,
        totalPerceptions: 0,
        saving: 0,
        guaranteedPercentage: 0,
        openingBonus: 0,
        fortnightId: '',
        fortnight: {
          id: '00000000-0000-0000-0000-000000000000',
          userId: '00000000-0000-0000-0000-000000000000',
          date: new Date(),
          active: true,
          numberFortnight: 0
        },
        fortnightCapital: 0,
        fortnightIVA: 0,
        fortnightInterest: 0,
        fortnightLifeInsurance: 0,
        iva: 0,
        lifeInsurance: 0,
        type: '',
        teachers: '',
        accountingConcept: ''
      },
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      teacherStatus: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: this.accountService.getUserId(),
        date: new Date,
        active: true,
        name: 'Activo',
        percentageSaving: 0,
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000'
      },
      acceptanceDate: new Date(),
      protectionPremium: 0,
      block: 0,
      questions: [],
      payments: [],
      settlements: [],
      settlementsSaving: [],
      settlementsTotal: 0,
      settlementsSavingTotal: 0,
      settlementsCapital: 0,
      settlementsInterest: 0,
      settlementsLifeInsurance: 0,
      settlementsLifeCondoneLifeInsurance: 0,
      settlementsCondoneInterest: 0
    }
  };

  constructor( public accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private loanService: LoanService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private savingService: SavingService,
    private helperService: HelperService,
    private notificationService: NotificationService
    ) { }

  ngOnInit() {
    this.gettingData = true;
    this.buildForm();
    this.getYears();

    this.activatedRoute.params.subscribe(params => {
      if ( params['pathReturn'] ) {
        this.pathReturn = params['pathReturn'];
      }

      if ( params['loanId'] ) {
          this.loanService.getById( params['loanId'] )
            .subscribe(loan => {
              this.payment.loanId = loan.id;
              this.payment.teacherId = loan.teacherId;
              this.payment.loan = loan;
              this.payment.teacher = loan.teacher;
              this.forma.controls['amount'].patchValue(this.payment.loan.discount);
              this.forma.controls['amountWithdrawFortnight'].patchValue(this.payment.loan.discount);
              this.gettingData = false;

            }, error => this.setErrorMessage( error ) );
      }
    });
  }

  getYears(): void {
    for (let index = new Date().getFullYear() - 18; index < new Date().getFullYear() + 5; index++) {
      this.years.push(index);
    }
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      type: ['Abono'],
      movementDate: [`${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${
          this.formatDate(new Date().getDate()) }`, [Validators.required]],
      folio: ['', [Validators.required]],
      depositAmount: ['', [Validators.required, Validators.min(1)]],
      year: [new Date().getFullYear() , [Validators.required]],
      fortnight: [''],
      yearEnd: [new Date().getFullYear() , [Validators.required]],
      fortnightEnd: [''],
      amount: ['', [Validators.required, Validators.min(0)]],
      interest: ['0', [Validators.required, Validators.min(0)]],
      condoneInterest: ['0', [Validators.required, Validators.min(0)]],
      financialProducts: ['0', [Validators.required, Validators.min(0)]],
      amountSaving: ['0', [Validators.required, Validators.min(0)]],
      lifeInsurance: ['0', [Validators.required, Validators.min(0)]],
      condoneLifeInsurance: ['0', [Validators.required, Validators.min(0)]],
      payments: this.formBuilder.array([]),
      yearSaving: [new Date().getFullYear()],
      fortnightSaving: [''],
      yearEndSaving: [new Date().getFullYear()],
      fortnightEndSaving: [''],
      amountWithdrawFortnight: ['', [Validators.min(0)]],
      // amountWithdraw: ['', [Validators.required, Validators.min(0)]],
      yearApply: [new Date().getFullYear()],
      fortnightApply: [''],
      interestWithdraw: ['0', [Validators.required, Validators.min(0)]],
      condoneInterestWithdraw: ['0', [Validators.required, Validators.min(0)]],
      lifeInsuranceWithdraw: ['0', [Validators.required, Validators.min(0)]],
      condoneLifeInsuranceWithdraw: ['0', [Validators.required, Validators.min(0)]],
      paymentsSaving: this.formBuilder.array([]),
    });
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  setErrorMessage( error: any ) {
    console.log(error);
    if (error && error.error) {
      this.notificationService.showErrorMessage(error.message);
      this.gettingData = false;
      this.sendingData = false;
    }
  }

  calculateAmount(): void {
    let amount = 0;
    for (let index = 1; index <= 24; index++) {
      amount += this.forma.controls['fortnight' + index].value;
    }
    this.amount = amount;
  }

  sendForm(): void {
    this.notificationService.showConfirm(`Seguro que desea agregar el folio ${ this.forma.controls['folio'].value }`)
    .then((result) => {
      if(result.isConfirmed){
        this.sendingData = true;
        if (this.amountPaymentValid()) {
          this.paymentsDTO = [];
          this.addPaymentList();
          this.addPaymentSavingList();
          this.paymentService.create( this.paymentsDTO )
              .subscribe(success => {
                this.notificationService.toastConfirm(`El folio ${this.forma.controls['folio'].value} se agregó correctamente`);
                this.router.navigate(['administration/loans', '']);
              }, error => this.setErrorMessage(error));
        } else {
          this.sendingData = false;
        }
      }
    });
  }

  showAlert() {
    this.notificationService.showErrorMessage('Tests');
  }

  addPayment() {
    // tslint:disable-next-line:max-line-length
    const startMonth = ( this.forma.controls['fortnight'].value.length === 1 ) ? `0${ this.forma.controls['fortnight'].value }` : this.forma.controls['fortnight'].value;
    // tslint:disable-next-line:max-line-length
    const endMonth = ( this.forma.controls['fortnightEnd'].value.length === 1 ) ? `0${ this.forma.controls['fortnightEnd'].value }` : this.forma.controls['fortnightEnd'].value;
    const startPeriod = parseFloat(this.forma.controls['year'].value + startMonth);
    const endPeriod = parseFloat(this.forma.controls['yearEnd'].value + endMonth);

    if ( startPeriod <= endPeriod ) {
      const payments = this.getNumberOfPayments(
        parseFloat( this.forma.controls['year'].value ),
        parseFloat( this.forma.controls['fortnight'].value ),
        parseFloat(this.forma.controls['yearEnd'].value),
        parseFloat(this.forma.controls['fortnightEnd'].value) );
      const amountPayments = parseFloat((this.amount + (payments * this.forma.controls['amount'].value)).toFixed(2));
      const interest = payments * ( this.payment.loan.loanParameter.fortnightIVA + this.payment.loan.loanParameter.fortnightInterest);
      // tslint:disable-next-line:max-line-length
      const depositAmount = parseFloat((parseFloat(this.forma.controls['depositAmount'].value) + parseFloat(this.forma.controls['condoneInterest'].value)).toFixed(2));
      this.amount = 0;
      this.proccessPayments( 'payments',
        payments,
        parseFloat( this.forma.controls['amount'].value ),
        parseFloat(this.forma.controls['year'].value),
        parseFloat(this.forma.controls['fortnight'].value) );
      this.amount = amountPayments;
      const financialProduct = ((depositAmount - this.amount) < 0) ? 0 : depositAmount - this.amount;
      this.forma.controls['financialProducts'].patchValue(financialProduct.toFixed(2));
      this.forma.controls['interest'].patchValue( interest.toFixed(2) );
      // tslint:disable-next-line:max-line-length
      this.forma.controls['lifeInsurance'].patchValue( (payments * this.payment.loan.loanParameter.fortnightLifeInsurance).toFixed(2) );
    } else {
      this.notificationService.showErrorMessage('El periodo final no puede ser mayor al inicial.');
    }
  }

  addPaymentwithdraw() {
    this.amountWithdraw = 0;
    // tslint:disable-next-line:max-line-length
    const startMonth = ( this.forma.controls['fortnightSaving'].value.length === 1 ) ? `0${ this.forma.controls['fortnightSaving'].value }` : this.forma.controls['fortnightSaving'].value;
    // tslint:disable-next-line:max-line-length
    const endMonth = ( this.forma.controls['fortnightEndSaving'].value.length === 1 ) ? `0${ this.forma.controls['fortnightEndSaving'].value }` : this.forma.controls['fortnightEndSaving'].value;
    const startPeriod = parseFloat(this.forma.controls['yearSaving'].value + startMonth);
    const endPeriod = parseFloat(this.forma.controls['yearEndSaving'].value + endMonth);
    if ( startPeriod <= endPeriod ) {
      const payments = this.getNumberOfPayments(
        parseFloat( this.forma.controls['yearSaving'].value ),
        parseFloat( this.forma.controls['fortnightSaving'].value ),
        parseFloat(this.forma.controls['yearEndSaving'].value),
        parseFloat(this.forma.controls['fortnightEndSaving'].value) );
      this.amountWithdraw = parseFloat((this.amountWithdraw + (payments * this.forma.controls['amountWithdrawFortnight'].value)).toFixed(2));
      if( this.amountWithdraw <= ( this.amountSavings.amount + this.amountSavings.amountInterest ) ) {
        this.proccessPayments( 'paymentsSaving',
          payments,
          parseFloat( this.forma.controls['amountWithdrawFortnight'].value ),
          parseFloat(this.forma.controls['yearSaving'].value),
          parseFloat(this.forma.controls['fortnightSaving'].value) );
        const interest = payments * ( this.payment.loan.loanParameter.fortnightIVA + this.payment.loan.loanParameter.fortnightInterest);
        this.forma.controls['interestWithdraw'].patchValue( interest.toFixed(2) );
        this.forma.controls['lifeInsuranceWithdraw'].patchValue( (payments * this.payment.loan.loanParameter.fortnightLifeInsurance).toFixed(2) );
      } else {
        this.notificationService.showErrorMessage('El desglose de las quincenas no debe de exceder el importe ahorrado.');
      }

    } else {
      this.notificationService.showErrorMessage('El periodo final no puede ser mayor al inicial.');
    }
  }

  proccessPayments( array: string, payments: number, amount: number, year: number, fortnight: number ): void {
    const phonesArr = this.forma.get( array ) as UntypedFormArray;
    while (phonesArr.length !== 0) {
      phonesArr.removeAt(0);
    }

    for (let index = 1; index <= payments; index++) {
      const paymentsArr = this.forma.get( array ) as UntypedFormArray;
      const beneficiariesFG = this.BuildPayment(amount, year, fortnight );
      paymentsArr.push(beneficiariesFG);
      fortnight++;
      if ( fortnight > 24 ) {
        year++;
        fortnight = 1;
      }
    }
  }

  getNumberOfPayments( anioStart: number, fortnightStart: number, anioEnd: number, fortnightEnd: number ): number {
    const yearsDifference = anioEnd - anioStart;
    const fortnightsDifference = (fortnightEnd + 1) - fortnightStart;
    if ( yearsDifference === 0 ) {
      return fortnightsDifference;
    }
    return ((yearsDifference * 24) - (fortnightStart - 1)) + fortnightEnd;
  }

  formInvalid(): boolean {
    return true;
  }

  updateFortnight(year: number, fortnight: number, amount: number, type: string): void {

    this.paymentsDTO.forEach(pay => {
      if ( pay.year === year && pay.type === type ) {
        switch ( fortnight.toString() ) {
          case '1':
              pay.fortnight1 = amount;
            break;
          case '2':
              pay.fortnight2 = amount;
            break;
          case '3':
              pay.fortnight3 = amount;
            break;
          case '4':
              pay.fortnight4 = amount;
            break;
          case '5':
              pay.fortnight5 = amount;
            break;
          case '6':
              pay.fortnight6 = amount;
            break;
          case '7':
              pay.fortnight7 = amount;
            break;
          case '8':
              pay.fortnight8 = amount;
            break;
          case '9':
              pay.fortnight9 = amount;
            break;
          case '10':
              pay.fortnight10 = amount;
            break;
          case '11':
              pay.fortnight11 = amount;
            break;
          case '12':
              pay.fortnight12 = amount;
            break;
          case '13':
              pay.fortnight13 = amount;
            break;
          case '14':
              pay.fortnight14 = amount;
            break;
          case '15':
              pay.fortnight15 = amount;
            break;
          case '16':
              pay.fortnight16 = amount;
            break;
          case '17':
              pay.fortnight17 = amount;
            break;
          case '18':
              pay.fortnight18 = amount;
            break;
          case '19':
              pay.fortnight19 = amount;
            break;
          case '20':
              pay.fortnight20 = amount;
            break;
          case '21':
              pay.fortnight21 = amount;
            break;
          case '22':
              pay.fortnight22 = amount;
            break;
          case '23':
              pay.fortnight23 = amount;
            break;
          case '24':
              pay.fortnight24 = amount;
            break;
        }
      }
    });
  }

  amountPaymentValid(): boolean {
    // tslint:disable-next-line:max-line-length
    let amount = parseFloat(this.forma.controls['amountSaving'].value) + parseFloat( this.forma.controls['financialProducts'].value );
    const discounts = parseFloat(this.forma.controls['condoneLifeInsurance'].value) + parseFloat(this.forma.controls['condoneInterestWithdraw'].value) + parseFloat(this.forma.controls['condoneLifeInsuranceWithdraw'].value);
    // tslint:disable-next-line:max-line-length
    const amountDeposit = parseFloat((parseFloat(this.forma.controls['depositAmount'].value) + parseFloat(this.forma.controls['condoneInterest'].value)).toFixed(2));
    const paymentsArr = this.forma.get('payments') as UntypedFormArray;
    for (let index = 0; index < paymentsArr.length; index++) {
      const payment = paymentsArr.at(index) as UntypedFormGroup;
      amount += parseFloat(payment.controls['amount'].value);
    }
    const paymentsSavingsArr = this.forma.get('paymentsSaving') as UntypedFormArray;
    for (let index = 0; index < paymentsSavingsArr.length; index++) {
      const payment = paymentsSavingsArr.at(index) as UntypedFormGroup;
      amount += parseFloat(payment.controls['amount'].value);
    }

    amount -= discounts;
    console.log(`Amount ${amount} deposit ${amountDeposit}`);
    if ( parseFloat(amount.toFixed(2)) === amountDeposit ) {
      return true;
    }
    if ( amount <  amountDeposit ) {
      this.amount = amount;
      this.notificationService.showErrorMessage(`El importe del acumulado ${ this.amount } no puede ser menos al importe del deposito ${ amountDeposit }.`);
    }
    if ( amount > amountDeposit ) {
      this.amount = amount;
      this.notificationService.showErrorMessage(`El importe del acumulado ${ this.amount } no puede ser mayor al importe del deposito ${ amountDeposit }.`);
    }
    return false;
  }

  addPaymentList(): void {
    const paymentsArr = this.forma.get('payments') as UntypedFormArray;
    let amountSaving = 0;
    let depositAmount = 0;
    let interest = 0;
    let condoneInterest = 0;
    let financialProducts = 0;
    let lifeInsurance = 0;
    let condoneLifeInsurance = 0;

    for (let index = 0; index < paymentsArr.length; index++) {
      const payment = paymentsArr.at(index) as UntypedFormGroup;

      if( index === 0 ) {
        amountSaving = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['amountSaving'].value : 0;
        depositAmount = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['depositAmount'].value : 0;
        interest = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['interest'].value : 0;
        condoneInterest = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['condoneInterest'].value : 0;
        financialProducts = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['financialProducts'].value : 0;
        lifeInsurance = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['lifeInsurance'].value : 0;
        condoneLifeInsurance = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['condoneLifeInsurance'].value : 0;
      } else {
        amountSaving = 0;
        depositAmount = 0;
        interest = 0;
        condoneInterest = 0;
        financialProducts = 0;
        lifeInsurance = 0;
        condoneLifeInsurance = 0;
      }

      if ( this.existYear(
        payment.controls['year'].value,
        this.forma.controls['type'].value ) ) {
        this.updateFortnight(
          parseFloat(payment.controls['year'].value),
          payment.controls['fortnight'].value,
          payment.controls['amount'].value,
          this.forma.controls['type'].value );
      } else {
        this.addElementToList(
          parseFloat(payment.controls['year'].value),
          payment.controls['fortnight'].value,
          payment.controls['amount'].value,
          this.forma.controls['type'].value,
          '',
          amountSaving,
          depositAmount,
          interest,
          condoneInterest,
          financialProducts,
          lifeInsurance,
          condoneLifeInsurance,
          //TODO: Modify to pass capital
          0);
      }
    }
  }

  addPaymentSavingList(): void {
    const paymentsArr = this.forma.get('paymentsSaving') as UntypedFormArray;
    const applyFortnigt = ( this.forma.controls['fortnightApply'].value.length === 1 ) ? `0${ this.forma.controls['fortnightApply'].value }` : this.forma.controls['fortnightApply'].value;
    let amountSaving = 0;
    let depositAmount = 0;
    let interest = 0;
    let condoneInterest = 0;
    let financialProducts = 0;
    let lifeInsurance = 0;
    let condoneLifeInsurance = 0;

    for (let index = 0; index < paymentsArr.length; index++) {
      const payment = paymentsArr.at(index) as UntypedFormGroup;
      if( index === 0 ) {
        interest = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['interestWithdraw'].value : 0;
        condoneInterest = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['condoneInterestWithdraw'].value : 0;
        lifeInsurance = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['lifeInsuranceWithdraw'].value : 0;
        condoneLifeInsurance = (this.forma.controls['type'].value === 'Liquidación') ? this.forma.controls['condoneLifeInsuranceWithdraw'].value : 0;
      } else {
        interest = 0;
        condoneInterest = 0;
        lifeInsurance = 0;
        condoneLifeInsurance = 0;
      }

      if ( this.existYear( payment.controls['year'].value, 'Ahorro' ) ) {
        this.updateFortnight(
          parseFloat(payment.controls['year'].value),
          payment.controls['fortnight'].value,
          payment.controls['amount'].value,
          'Ahorro' );
      } else {
        this.addElementToList(
          parseFloat(payment.controls['year'].value),
          payment.controls['fortnight'].value,
          payment.controls['amount'].value,
          'Ahorro',
          `${ this.forma.controls['yearApply'].value }-${ applyFortnigt }`,
          amountSaving,
          depositAmount,
          interest,
          condoneInterest,
          financialProducts,
          lifeInsurance,
          condoneLifeInsurance,
          //TODO: Modified to pass the capital
          0);
      }
    }
  }

  addElementToList(
    year: number,
    fortnight: number,
    amount: number,
    type: string,
    applyFortnight: string,
    amountSaving: number,
    depositAmount: number,
    interest: number,
    condoneInterest: number,
    financialProducts: number,
    lifeInsurance: number,
    condoneLifeInsurance: number,
    capital: number): void {

    const payment: PaymentDTOInterface = {
      userId: this.payment.userId,
      loanId: this.payment.loanId,
      teacherId: this.payment.teacherId,
      type: type,
      movementDate: this.forma.controls['movementDate'].value,
      folio: this.forma.controls['folio'].value,
      amountSaving: amountSaving,
      year: year,
      amount: depositAmount,
      interest: interest,
      fortnight1: 0,
      fortnight2: 0,
      fortnight3: 0,
      fortnight4: 0,
      fortnight5: 0,
      fortnight6: 0,
      fortnight7: 0,
      fortnight8: 0,
      fortnight9: 0,
      fortnight10: 0,
      fortnight11: 0,
      fortnight12: 0,
      fortnight13: 0,
      fortnight14: 0,
      fortnight15: 0,
      fortnight16: 0,
      fortnight17: 0,
      fortnight18: 0,
      fortnight19: 0,
      fortnight20: 0,
      fortnight21: 0,
      fortnight22: 0,
      fortnight23: 0,
      fortnight24: 0,
      capital: 0,
      condoneFortnight: 0,
      condoneInterest: condoneInterest,
      financialProducts: financialProducts,
      lifeInsurance: lifeInsurance,
      condoneLifeInsurance: condoneLifeInsurance,
      applyFortnight: applyFortnight,
      settlementId: '00000000-0000-0000-0000-000000000000'
    };

    this.SetFortnightValue(payment, fortnight.toString(), amount );
    this.paymentsDTO.push( payment );
  }

  existYear(year: number, type: string): boolean {
    const result = this.paymentsDTO.filter(p => p.year === year && p.type === type );
    if ( result.length > 0 ) {
      return true;
    }
    return false;
  }

  existYearFortnight(arrForm: UntypedFormArray): boolean {
    let result = false;

    for (let i = 0; i < arrForm.length; i++) {
      const element = arrForm.at(i) as UntypedFormGroup;
      if ( element.controls['year'].value === this.forma.controls['year'].value
        && element.controls['fortnight'].value === this.forma.controls['fortnight'].value) {
          result = true;
        }
    }

    return result;
  }

  BuildPayment( amount: number, year: number, fortnight: number ) {
    /* return this.formBuilder.group({
      year: this.forma.controls['year'].value,
      fortnight: this.forma.controls['fortnight'].value,
      amount: this.forma.controls['amount'].value
    }); */
    return this.formBuilder.group({
      year: year,
      fortnight: fortnight,
      amount: [amount, [Validators.required, Validators.min(0)]]
    });
  }

  SetFortnightValue(payment: PaymentDTOInterface, fortnight: string, amount: number): void {
    switch ( fortnight ) {
      case '1':
        payment.fortnight1 = amount;
        break;
      case '2':
        payment.fortnight2 = amount;
        break;
      case '3':
        payment.fortnight3 = amount;
        break;
      case '4':
        payment.fortnight4 = amount;
        break;
      case '5':
        payment.fortnight5 = amount;
        break;
      case '6':
        payment.fortnight6 = amount;
        break;
      case '7':
        payment.fortnight7 = amount;
        break;
      case '8':
        payment.fortnight8 = amount;
        break;
      case '9':
        payment.fortnight9 = amount;
        break;
      case '10':
        payment.fortnight10 = amount;
        break;
      case '11':
        payment.fortnight11 = amount;
        break;
      case '12':
        payment.fortnight12 = amount;
        break;
      case '13':
        payment.fortnight13 = amount;
        break;
      case '14':
        payment.fortnight14 = amount;
        break;
      case '15':
        payment.fortnight15 = amount;
        break;
      case '16':
        payment.fortnight16 = amount;
        break;
      case '17':
        payment.fortnight17 = amount;
        break;
      case '18':
        payment.fortnight18 = amount;
        break;
      case '19':
        payment.fortnight19 = amount;
        break;
      case '20':
        payment.fortnight20 = amount;
        break;
      case '21':
        payment.fortnight21 = amount;
        break;
      case '22':
        payment.fortnight22 = amount;
        break;
      case '23':
        payment.fortnight23 = amount;
        break;
      case '24':
        payment.fortnight24 = amount;
        break;
    }
  }

  handlerChangeType(): void {
    if ( this.forma.controls['type'].value === 'Abono' ) {
      this.forma.controls['amountSaving'].patchValue('0');
      this.forma.controls['financialProducts'].patchValue('0');
      this.forma.controls['interest'].patchValue('0');
      this.forma.controls['condoneInterest'].patchValue('0');
      this.amount = 0;
      const phonesArr = this.forma.get('payments') as UntypedFormArray;
      while (phonesArr.length !== 0) {
        phonesArr.removeAt(0);
      }
    } else {
      const status = this.payment.teacher.teacherStatus.find( ts => ts.active === true );
      this.gettingData = true;
      if( this.amountSavings.amount === 0 ) {
        this.savingService.getByStatus( status.id )
          .subscribe( savings => {
            this.amountSavings = this.helperService.calculateAmountGlobal( savings, 0.00208 );
            this.gettingData = false;
          }, error => this.helperService.setErrorMessage( error ));
      } else {
        this.gettingData = false;
      }
    }
  }

  blurPayment(): void {
    let amount = 0;
    const paymentsArr = this.forma.get('payments') as UntypedFormArray;
    for (let index = 0; index < paymentsArr.length; index++) {
      const payment = paymentsArr.at(index) as UntypedFormGroup;
      amount += parseFloat(payment.controls['amount'].value);
    }
    this.amount = amount;
  }

  blurPaymentWithdraw(): void {
    let amount = 0;
    const paymentsArr = this.forma.get('paymentsSaving') as UntypedFormArray;

    for (let index = 0; index < paymentsArr.length; index++) {
      const payment = paymentsArr.at(index) as UntypedFormGroup;
      amount += parseFloat(payment.controls['amount'].value);
    }
    this.amountWithdraw = amount;
  }

  focusInterestWithdraw(): void {
    if ( parseFloat(this.forma.controls['condoneInterestWithdraw'].value) <= 0 ) {
      this.forma.controls['condoneInterestWithdraw'].patchValue( this.forma.controls['interestWithdraw'].value );
      this.forma.controls['interestWithdraw'].patchValue( 0 );
    }
  }
}
