<div class="content">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">Administración</li>
      <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]"
          routerLinkActive="router-link-active">Prestamos</a></li>
    </ol>
  </nav>

  <div class="title-search-block">
    <div class="title-block">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title">
            <h3 class="title">{{ title }}</h3>
          </h3>
        </div>
      </div>
    </div>
  </div>

  <app-loading-data *ngIf="LoadingData"></app-loading-data>

  <div class="card card-block animated fadeIn fast">
    <form [formGroup]="forma" (ngSubmit)="sendForm()">
      <div class="row">
        <div class="col-md-12 text-center">
          <h4 class="info"><b>{{ loan.teacher.name | uppercase }} <small>({{loan.teacher.rfc | uppercase}})</small></b>
          </h4>
          <hr>
          <div class="alert alert-danger " role="alert" *ngIf="loanPending.length > 0 || showSavings">
            <span *ngIf="loanPending.length === 1">El maestro cuenta con {{ loanPending.length }} préstamo pendiente. Importe total {{grandTotalBalance | currency}}</span>
            <span *ngIf="loanPending.length > 1">El maestro cuenta con {{ loanPending.length }} préstamos pendiente. Importe total {{grandTotalBalance | currency}}</span>
            <span *ngIf="showSavings"><br/>El maestro no cuenta con el ahorro necesario ({{requiredSavings|currency}} para el préstamo), actualmente tiene {{ amountSavings |currency }} considerando el 5% de interés. Se le registrará una aportación por {{requiredSavings - amountSavings|currency}}</span>
          </div>
        </div>
      </div>

      <div *ngIf="showPayments && haveTemporal">
        <form [formGroup]="paymentForm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10">
              <h4>Cheque/Transferencia {{loanPending[indexPendings].loan.checkNumber}} No. Hoja {{loanPending[indexPendings].loan.loanNumber}}</h4>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-center mb-2">
              <a class="btn btn-outline-info" [routerLink]="['/administration/payments/add', loanPending[indexPendings].loan.id, '/administration/loans/add/' + loanPending[indexPendings].teacher.id ]" >
                  <i class="far fa-plus-square"></i> boucher
              </a>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="alert alert-warning text-center" role="alert" *ngIf="loanPending.length > 0">
                <span><b>Adeudo {{ balance |currency}} qnas pendientes {{loanPending[indexPendings].fortnightPendings}} <span style="color: green">qnas a aplicar {{loanPending[indexPendings].fortnightPendings - qnasApplied.value}}</span><span style="color: crimson;"> incompletas {{loanPending[indexPendings].numberIncompleteFortnights}}</span></b></span>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1">
              <div class="form-group"
                [ngClass]="{'has-error': !paymentForm.controls['capital'].valid && paymentForm.controls['capital'].touched, 'has-success': paymentForm.controls['capital'].valid }">
                <label class="control-label">Capital</label>
                <input type="number" class="form-control underlined" formControlName="capital">
                <span
                  *ngIf="paymentForm.controls['capital'].touched && paymentForm.controls['capital'].errors?.required"
                  class="has-error ">
                  El campo es requerido.
                </span>
                <span
                  *ngIf="paymentForm.controls['capital'].touched && paymentForm.controls['capital'].errors?.min"
                  class="has-error ">
                  El valor mínino es 0.
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <div class="form-group ">
                <label class="control-label ">Qnas aplicadas</label>
                <select class="custom-select" #qnasApplied (change)="onChange($event.target.value)" formControlName="condonFortnight" >
                  <option value="0" >0</option>
                  <option value="1" >1</option>
                  <option value="2" >2</option>
                  <option value="3" >3</option>
                  <option value="4" >4</option>
                </select>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <div class="form-group"
                [ngClass]="{'has-error': !paymentForm.controls['interest'].valid && paymentForm.controls['interest'].touched, 'has-success': paymentForm.controls['interest'].valid }">
                <label class="control-label">
                  Interes a cobrar
                </label>
                <input type="number" class="form-control underlined" formControlName="interest" (blur)="onBlurInterest($event)">
                <span
                  *ngIf="paymentForm.controls['interest'].touched && paymentForm.controls['interest'].errors?.required"
                  class="has-error ">
                  El campo es requerido.
                </span>
                <span *ngIf="paymentForm.controls['interest'].touched && paymentForm.controls['interest'].errors?.min"
                  class="has-error ">
                  El valor mínino es 0.
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <div class="form-group"
                [ngClass]="{'has-error': !paymentForm.controls['condoneInterest'].valid && paymentForm.controls['condoneInterest'].touched, 'has-success': paymentForm.controls['condoneInterest'].valid }">
                <label class="control-label">
                  Interes condonado
                </label>
                <input type="number" class="form-control underlined" formControlName="condoneInterest">
                <span
                  *ngIf="paymentForm.controls['condoneInterest'].touched && paymentForm.controls['condoneInterest'].errors?.required"
                  class="has-error ">
                  El campo es requerido.
                </span>
                <span
                  *ngIf="paymentForm.controls['condoneInterest'].touched && paymentForm.controls['condoneInterest'].errors?.min"
                  class="has-error ">
                  El valor mínino es 0.
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <div class="form-group"
                [ngClass]="{'has-error': !paymentForm.controls['lifeInsurance'].valid && paymentForm.controls['lifeInsurance'].touched, 'has-success': paymentForm.controls['lifeInsurance'].valid }">
                <label class="control-label">Prima de garantia a cobrar</label>
                <input type="number" class="form-control underlined" formControlName="lifeInsurance" (blur)="onBlurLifeInsurance($event)">
                <span
                  *ngIf="paymentForm.controls['lifeInsurance'].touched && paymentForm.controls['lifeInsurance'].errors?.required"
                  class="has-error ">
                  El campo es requerido.
                </span>
                <span
                  *ngIf="paymentForm.controls['lifeInsurance'].touched && paymentForm.controls['lifeInsurance'].errors?.min"
                  class="has-error ">
                  El valor mínino es 0.
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
              <div class="form-group"
                [ngClass]="{'has-error': !paymentForm.controls['condoneLifeInsurance'].valid && paymentForm.controls['condoneLifeInsurance'].touched, 'has-success': paymentForm.controls['condoneLifeInsurance'].valid }">
                <label class="control-label">Prima de garantia condonada</label>
                <input type="number" class="form-control underlined" formControlName="condoneLifeInsurance">
                <span
                  *ngIf="paymentForm.controls['condoneLifeInsurance'].touched && paymentForm.controls['condoneLifeInsurance'].errors?.required"
                  class="has-error ">
                  El campo es requerido.
                </span>
                <span
                  *ngIf="paymentForm.controls['condoneLifeInsurance'].touched && paymentForm.controls['condoneLifeInsurance'].errors?.min"
                  class="has-error ">
                  El valor mínino es 0.
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <div class="form-group ">
                <label class="control-label ">Año inicio</label>
                <select class="custom-select" formControlName="year">
                  <option [value]="year" *ngFor="let year of years ">{{ year }}</option>
                </select>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <div class="form-group ">
                <label class="control-label ">Qna inicio</label>
                <select class="custom-select" formControlName="fortnight">
                  <option [value]="fortnight" *ngFor="let fortnight of fortnights ">{{ fortnight }}</option>
                </select>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <div class="form-group"
                [ngClass]="{'has-error': !paymentForm.controls['amount'].valid && paymentForm.controls['amount'].touched, 'has-success': paymentForm.controls['amount'].valid }">
                <label class="control-label">Importe</label>
                <input type="number" class="form-control underlined" formControlName="amount" (blur)="onBlurAmountFortnight()">
                <span *ngIf="paymentForm.controls['amount'].touched && paymentForm.controls['amount'].errors?.required"
                  class="has-error ">
                  El campo es requerido.
                </span>
                <span *ngIf="paymentForm.controls['amount'].touched && paymentForm.controls['amount'].errors?.min"
                  class="has-error ">
                  El valor mínino es 1.
                </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex justify-content-center align-items-center">
              <button type="button" class="btn btn-outline-secondary" [disabled]="this.paymentForm.controls['amount'].value < 0 || this.paymentForm.controls['fortnight'].value === '' || balance === 0" (click)="addPayment()">
                  <i class="far fa-plus-square"></i> Agregar
              </button>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2">
              <div class="form-group">
                <label class="control-label">Acumulado</label>
                <input type="text" maxlength="250" class="form-control underlined" readonly value="{{ amount | currency }}">
              </div>
            </div>
          </div>

          <div class="row">
            <div formArrayName="payments" *ngFor="let payment of paymentForm.get('payments')['controls']; let i = index" >
                <div [formGroupName]="i">
                    <div class="row" *ngIf="payment.get('year').value">
                        <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1">
                            <div class="form-group">
                                <label class="control-label">#</label>
                                <input type="text" maxlength="250" class="form-control underlined" readonly [value]="i + 1">
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                            <div class="form-group">
                                <label class="control-label">Año</label>
                                <input type="text" maxlength="250" class="form-control underlined" readonly name="year" formControlName="year">
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                            <div class="form-group">
                                <label class="control-label">Quincena</label>
                                <input type="text" maxlength="250" class="form-control underlined" readonly name="fortnight" formControlName="fortnight">
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                            <div class="form-group">
                                <label class="control-label">Importe</label>
                                <input type="number" class="form-control underlined" name="amount" formControlName="amount" (blur)="blurPayment();">
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex justify-content-center align-items-center">
                          <button type="button" class="btn btn-outline-danger" (click)="deleteFortnight( i )">
                            <i class="far fa-trash-alt"></i> Eliminar
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group text-center mt-3">
          <button type="button" [disabled]="!paymentForm.valid || sendingData || (paymentForm.get('payments')['controls'].length === 0 && balance > 0)" class="btn btn-outline-success" (click)="addPaymentForm()">
            <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
            <span *ngIf="!sendingData "> Guardar</span>
            <span *ngIf="sendingData "> Guardando...</span>
          </button>
      </div>

        </form>
      </div>

      <div *ngIf="!haveTemporal">
        <div class="row">

          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label class="control-label"><i class="fas fa-asterisk required"></i> Prefijos</label>
              <select class="custom-select" *ngIf="!editing" name="prefix" (change)="setPrefixParameter( )"
                formControlName="prefix">
                <option value="">Seleccionar...</option>
                <option [value]="parameter" *ngFor="let parameter of prefixes ">{{ parameter }} </option>
              </select>
              <select class="custom-select" *ngIf="editing" disabled name="prefix" (change)="setPrefixParameter( )"
                formControlName="prefix">
                <option value="">Seleccionar...</option>
                <option [value]="parameter" *ngFor="let parameter of prefixes ">{{ parameter }} </option>
              </select>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['loanNumber'].valid && forma.controls['loanNumber'].touched, 'has-success': forma.controls['loanNumber'].valid && forma.controls['loanNumber'].touched }">
              <label class="control-label"><i class="fas fa-asterisk required"></i> # Hoja</label>
              <input type="text" class="form-control underlined" maxlength="30" formControlName="loanNumber">
              <span *ngIf="forma.controls['loanNumber'].touched && forma.controls['loanNumber'].errors?.required"
                class="has-error ">
                El campo es requerido.
              </span>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div class="row">
              <label class="control-label">Sorteo</label>
            </div>
            <div class="row">
              <h3 class="control-label">{{ loan.draw.name }}</h3>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div class="row">
              <label class="control-label">Depósito</label>
            </div>
            <div class="row">
              <h3 class="control-label">{{ deposit|currency }}</h3>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label class="control-label"><i class="fas fa-asterisk required"></i> Monto / Quincenas</label>
              <select *ngIf="!editing" class="custom-select" name="loanParameterId" (change)="changeParameter(  )"
                formControlName="loanParameterId">
                <option value="">Seleccionar...</option>
                <option [value]="parameter.id" *ngFor="let parameter of prefixesParameters">
                  {{ parameter.amount | currency }} / {{ parameter.fortnight.numberFortnight }}</option>
              </select>
              <select *ngIf="editing" disabled class="custom-select" name="loanParameterId" (change)="changeParameter(  )" formControlName="loanParameterId">
                <option value="">Seleccionar...</option>
                <option [value]="parameter.id" *ngFor="let parameter of prefixesParameters">
                  {{ parameter.amount | currency }} / {{ parameter.fortnight.numberFortnight }}</option>
              </select>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div class="row">
              <label class="control-label">Interes</label>
            </div>
            <div class="row">
              <h3 class="control-label text-danger">{{ loan.interests | currency }}</h3>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div class="row">
              <label class="control-label">Descuento</label>
            </div>
            <div class="row">
              <h3 class="control-label text-danger">{{ loan.discount | currency }}</h3>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label class="control-label"><i class="fas fa-asterisk required"></i> Región</label>
              <select class="custom-select" name="regionId" disabled formControlName="regionId" *ngIf="editing">
                <option [value]="region.id" *ngFor="let region of regions ">{{ region.name }}</option>
              </select>
              <select class="custom-select" name="regionId" formControlName="regionId" *ngIf="!editing">
                <option [value]="region.id" *ngFor="let region of regions ">{{ region.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Inicial</label>
              <select class="custom-select" name="fortnightStart" (change)="changeFortnightStart()"
                formControlName="fortnightStart">
                <option [value]="fortnight" *ngFor="let fortnight of fortnightsStart ">{{ fortnight }}</option>
              </select>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Final</label>
              <select class="custom-select" name="fortnightEnd" formControlName="fortnightEnd">
                <option value="">Seleccionar...</option>
                <option [value]="fortnight" *ngFor="let fortnight of fortnightsEnd ">{{ fortnight }}</option>
              </select>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngIf="!editing">
            <div class="form-group">
              <label class="control-label">Cumple con la liquidez</label>
              <select class="custom-select" name="haveFinancialLiquidity"
                formControlName="haveFinancialLiquidity">
                <option value="false" selected="selected">No</option>
                <option value="true">Si</option>
              </select>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngIf="!haveAdmission">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['teacherAdmission'].valid && forma.controls['teacherAdmission'].touched, 'has-success': forma.controls['teacherAdmission'].valid && forma.controls['teacherAdmission'].touched }">
              <label class="control-label"><i class="fas fa-asterisk required"></i> Fecha Admisión del maestro</label>
              <input type="date" #admission class="form-control underlined" formControlName="teacherAdmission"
                (blur)="blurAdmission( admission.value )">
              <span
                *ngIf="forma.controls['teacherAdmission'].touched && forma.controls['teacherAdmission'].errors?.required"
                class="has-error ">
                El campo es requerido.
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="!loan.teacher.street">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['street'].valid && forma.controls['street'].touched, 'has-success': forma.controls['street'].valid && forma.controls['street'].touched }">
              <label class="control-label"> Calle</label>
              <input type="text" class="form-control underlined" maxlength="150" formControlName="street">
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-1" *ngIf="!loan.teacher.street">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['number'].valid && forma.controls['number'].touched, 'has-success': forma.controls['number'].valid && forma.controls['number'].touched }">
              <label class="control-label"> Número</label>
              <input type="text" maxlength="10" class="form-control underlined" formControlName="number">
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="!loan.teacher.street">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['colony'].valid && forma.controls['colony'].touched, 'has-success': forma.controls['colony'].valid && forma.controls['colony'].touched }">
              <label class="control-label"> Colonia</label>
              <input type="text" maxlength="150" class="form-control underlined" formControlName="colony">
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-1" *ngIf="!loan.teacher.street">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['zipCode'].valid && forma.controls['zipCode'].touched, 'has-success': forma.controls['zipCode'].valid && forma.controls['zipCode'].touched }">
              <label class="control-label"> C. P.</label>
              <input type="number" minlength="5" maxlength="5" class="form-control underlined"
                formControlName="zipCode">
              <span *ngIf="forma.controls['zipCode'].touched && forma.controls['zipCode'].errors?.minlength"
                class="has-error">
                El campo debe tener al menos {{ forma.controls['zipCode'].errors.minlength.requiredLength }}
                caracteres.
              </span>
              <span *ngIf="forma.controls['zipCode'].touched && forma.controls['zipCode'].errors?.maxlength"
                class="has-error">
                El campo debe tener máximo {{ forma.controls['zipCode'].errors.maxlength.requiredLength }}
                caracteres.
              </span>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="!loan.teacher.email && !editing">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['email'].valid && forma.controls['email'].touched, 'has-success': forma.controls['email'].valid && forma.controls['email'].touched }">
              <label class="control-label"> Email</label>
              <input type="text" maxlength="50" class="form-control underlined" formControlName="email">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3"
            *ngIf="loan.teacher.teacherPhones.length === 0 && !editing">
            <div class="form-group">
              <label class="control-label"> Celular</label>
              <input type="text" maxlength="10" class="form-control underlined" formControlName="phone">
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngIf="!editing">
            <div class="form-group">
              <label class="control-label"><i class="fas fa-asterisk required"></i> Tipo de Movimiento</label>
              <select class="custom-select" name="movementType" (change)="handlerCHangeMovement(  )"
                formControlName="movementType">
                <option value="">Seleccionar...</option>
                <option [value]="movementType" *ngFor="let movementType of movementTypes ">{{ movementType }}</option>
              </select>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3"
            *ngIf="forma.controls['movementType'].value === 'Transferencia' && !editing">
            <div class="form-group">
              <label class="control-label">Banco</label>
              <select class="custom-select" name="bankId" formControlName="bankId" (change)="handlerBanks()">
                <option value="00000000-0000-0000-0000-000000000000">Seleccionar...</option>
                <option [value]="bank.id" *ngFor="let bank of banks ">{{ bank.name }}</option>
              </select>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3"
            *ngIf="forma.controls['movementType'].value === 'Transferencia' && forma.controls['bankId'].value === 'be28ff92-e845-41bb-8bee-3060267a7023' && !editing">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['account'].valid && forma.controls['account'].touched, 'has-success': forma.controls['account'].valid && forma.controls['account'].touched }">
              <label class="control-label"><i class="fas fa-asterisk required"></i> Cuenta</label>
              <input type="text" class="form-control underlined" minlength="10" maxlength="10"
                formControlName="account">
              <span *ngIf="forma.controls['account'].touched && forma.controls['account'].errors?.required"
                class="has-error ">
                El campo es requerido.
              </span>
              <span *ngIf="forma.controls['account'].touched && forma.controls['account'].errors?.minlength"
                class="has-error ">
                El campo debe de tener una longitud de 10 caracteres.
              </span>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3"
            *ngIf="forma.controls['movementType'].value === 'Transferencia' && forma.controls['bankId'].value !== 'be28ff92-e845-41bb-8bee-3060267a7023' && !editing">
            <div class="form-group"
              [ngClass]="{'has-error': !forma.controls['clabe'].valid && forma.controls['clabe'].touched, 'has-success': forma.controls['clabe'].valid && forma.controls['clabe'].touched }">
              <label class="control-label"><i class="fas fa-asterisk required"></i> CLABE</label>
              <input type="text" class="form-control underlined" minlength="18" maxlength="18" formControlName="clabe">
              <span *ngIf="forma.controls['clabe'].touched && forma.controls['clabe'].errors?.required"
                class="has-error ">
                El campo es requerido.
              </span>
              <span *ngIf="forma.controls['clabe'].touched && forma.controls['clabe'].errors?.minlength"
                class="has-error ">
                El campo debe de tener una longitud de 18 caracteres.
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="form-group" [ngClass]="{ 'has-success': forma.controls['comment'].touched }">
              <label class="control-label">Comentario</label>
              <input type="text" class="form-control underlined" maxlength="200" formControlName="comment">
              <span *ngIf="forma.controls['comment'].touched && forma.controls['comment'].errors?.minlength"
                class="has-error">
                El campo comentario debe tener {{ forma.controls['comment'].errors.minlength.requiredLength }}
                caracteres.
              </span>
            </div>
          </div>
        </div>

        <fieldset *ngIf="loansSettlements?.length > 0">
          <div class="row">
            <div class="col-md-2">
              <legend>Prestamos ( {{ loansSettlements?.length }} )</legend>
            </div>
          </div>

          <div class="accordion" id="accordionExample">
            <div class="card" *ngFor="let item of loansSettlements; let i = index">
              <div class="card-header" [id]="'heading' + i">
                <h5 class="mb-0">
                  <button class="btn btn-link" type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + i"
                    aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                    Monto: {{ item.amount | currency }} # Hoja: {{ item.loanNumber }} # Cheque /
                    Transferencia: {{ item.checkNumber }}<br>Qna Inicial: {{ item.fortnightStart }} Qna Final: {{
                    item.fortnightEnd }} Estatus: {{ item.status }}
                    Fecha de aplicación {{ item.acceptanceDate | date:'dd/MM/yyyy' }}
                  </button>
                </h5>
              </div>

              <div [id]="'collapse' + i " class="collapse show" [attr.aria-labelledby]="'heading' + i" data-parent="#accordionExample">
                <div class="card-body">
                  <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Año</th>
                            <th scope="col">Qna 1<br />Qna 13</th>
                            <th scope="col">Qna 2<br />Qna 14</th>
                            <th scope="col">Qna 3<br />Qna 15</th>
                            <th scope="col">Qna 4<br />Qna 16</th>
                            <th scope="col">Qna 5<br />Qna 17</th>
                            <th scope="col">Qna 6<br />Qna 18</th>
                            <th scope="col">Qna 7<br />Qna 19</th>
                            <th scope="col">Qna 8<br />Qna 20</th>
                            <th scope="col">Qna 9<br />Qna 21</th>
                            <th scope="col">Qna 10<br />Qna 22</th>
                            <th scope="col">Qna 11<br />Qna 23</th>
                            <th scope="col">Qna 12<br />Qna 24</th>
                            <th scope="col">Subtotal</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr class="text-right" *ngFor="let payment of item.payments; let i = index">
                            <td>{{ payment.year }}</td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 1, payment.fortnight1 )" href="" data-toggle="modal">
                                        {{ payment.fortnight1 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight1 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 13, payment.fortnight13 )" href="" data-toggle="modal">
                                        {{ payment.fortnight13 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight13 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 2, payment.fortnight2 )" href="" data-toggle="modal">
                                        {{ payment.fortnight2 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight2 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 14, payment.fortnight14 )" href="" data-toggle="modal">
                                        {{ payment.fortnight14 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight14 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 3, payment.fortnight3 )" href="" data-toggle="modal">
                                        {{ payment.fortnight3 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight3 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 15, payment.fortnight15 )" href="" data-toggle="modal">
                                        {{ payment.fortnight15 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight15 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 4, payment.fortnight4 )" href="" data-toggle="modal">
                                        {{ payment.fortnight4 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight4 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 16, payment.fortnight16 )" href="" data-toggle="modal">
                                        {{ payment.fortnight16 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight16 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 5, payment.fortnight5 )" href="" data-toggle="modal">
                                        {{ payment.fortnight5 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight5 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 17, payment.fortnight17 )" href="" data-toggle="modal">
                                        {{ payment.fortnight17 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight17 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 6, payment.fortnight6 )" href="" data-toggle="modal">
                                        {{ payment.fortnight6 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight6 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 18, payment.fortnight18 )" href="" data-toggle="modal">
                                        {{ payment.fortnight18 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight18 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 7, payment.fortnight7 )" href="" data-toggle="modal">
                                        {{ payment.fortnight7 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight7 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 19, payment.fortnight19 )" href="" data-toggle="modal">
                                        {{ payment.fortnight19 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight19 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 8, payment.fortnight8 )" href="" data-toggle="modal">
                                        {{ payment.fortnight8 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight8 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 20, payment.fortnight20 )" href="" data-toggle="modal">
                                        {{ payment.fortnight20 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight20 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 9, payment.fortnight9 )" href="" data-toggle="modal">
                                        {{ payment.fortnight9 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight9 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 21, payment.fortnight21 )" href="" data-toggle="modal">
                                        {{ payment.fortnight21 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight21 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 10, payment.fortnight10 )" href="" data-toggle="modal">
                                        {{ payment.fortnight10 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight10 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 22, payment.fortnight22 )" href="" data-toggle="modal">
                                        {{ payment.fortnight22 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight22 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 11, payment.fortnight11 )" href="" data-toggle="modal">
                                        {{ payment.fortnight11 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight11 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 23, payment.fortnight23 )" href="" data-toggle="modal">
                                        {{ payment.fortnight23 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight23 | currency }}
                                </span>
                            </td>
                            <td>
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 12, payment.fortnight12 )" href="" data-toggle="modal">
                                        {{ payment.fortnight12 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight12 | currency }}
                                </span>
                                <br />
                                <span *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')" >
                                    <a (click)="showConfirmChangeFortnight( payment, 24, payment.fortnight24 )" href="" data-toggle="modal">
                                        {{ payment.fortnight24 | currency }}
                                    </a>
                                </span>
                                <span *ngIf="!accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                                    {{ payment.fortnight24 | currency }}
                                </span>
                            </td>
                            <td>
                              {{ payment.amount | currency }}
                              <br />
                              {{ payment.type }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
              </div>

            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="loan.settlementsSaving?.length > 0">
          <div class="row">
            <div class="col">
              <legend>Aportaciones ( {{ loan.settlementsSaving?.length }} )</legend>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Año</th>
                        <th scope="col">Qna 1<br />Qna 13</th>
                        <th scope="col">Qna 2<br />Qna 14</th>
                        <th scope="col">Qna 3<br />Qna 15</th>
                        <th scope="col">Qna 4<br />Qna 16</th>
                        <th scope="col">Qna 5<br />Qna 17</th>
                        <th scope="col">Qna 6<br />Qna 18</th>
                        <th scope="col">Qna 7<br />Qna 19</th>
                        <th scope="col">Qna 8<br />Qna 20</th>
                        <th scope="col">Qna 9<br />Qna 21</th>
                        <th scope="col">Qna 10<br />Qna 22</th>
                        <th scope="col">Qna 11<br />Qna 23</th>
                        <th scope="col">Qna 12<br />Qna 24</th>
                        <th scope="col">Subtotal</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="text-right" *ngFor="let saving of loan.settlementsSaving; let i = index">
                        <td>{{ saving.year }}</td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 1, saving.fortnight1 )" href="" data-toggle="modal">
                                {{ saving.fortnight1 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight1 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 13, saving.fortnight13 )" href="" data-toggle="modal">
                                {{ saving.fortnight13 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight13 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 2, saving.fortnight2 )" href="" data-toggle="modal">
                                {{ saving.fortnight2 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight2 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 14, saving.fortnight14 )" href="" data-toggle="modal">
                                {{ saving.fortnight14 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight14 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 3, saving.fortnight3 )" href="" data-toggle="modal">
                                {{ saving.fortnight3 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight3 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 15, saving.fortnight15 )" href="" data-toggle="modal">
                                {{ saving.fortnight15 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight15 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 4, saving.fortnight4 )" href="" data-toggle="modal">
                                {{ saving.fortnight4 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight4 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 16, saving.fortnight16 )" href="" data-toggle="modal">
                                {{ saving.fortnight16 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight16 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 5, saving.fortnight5 )" href="" data-toggle="modal">
                                {{ saving.fortnight5 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight5 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 17, saving.fortnight17 )" href="" data-toggle="modal">
                                {{ saving.fortnight17 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight17 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 6, saving.fortnight6 )" href="" data-toggle="modal">
                                {{ saving.fortnight6 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight6 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 18, saving.fortnight18 )" href="" data-toggle="modal">
                                {{ saving.fortnight18 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight18 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 7, saving.fortnight7 )" href="" data-toggle="modal">
                                {{ saving.fortnight7 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight7 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 19, saving.fortnight19 )" href="" data-toggle="modal">
                                {{ saving.fortnight19 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight19 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 8, saving.fortnight8 )" href="" data-toggle="modal">
                                {{ saving.fortnight8 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight8 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 20, saving.fortnight20 )" href="" data-toggle="modal">
                                {{ saving.fortnight20 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight20 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 9, saving.fortnight9 )" href="" data-toggle="modal">
                                {{ saving.fortnight9 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight9 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 21, saving.fortnight21 )" href="" data-toggle="modal">
                                {{ saving.fortnight21 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight21 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 10, saving.fortnight10 )" href="" data-toggle="modal">
                                {{ saving.fortnight10 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight10 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 22, saving.fortnight22 )" href="" data-toggle="modal">
                                {{ saving.fortnight22 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight22 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 11, saving.fortnight11 )" href="" data-toggle="modal">
                                {{ saving.fortnight11 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight11 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 23, saving.fortnight23 )" href="" data-toggle="modal">
                                {{ saving.fortnight23 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight23 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 12, saving.fortnight12 )" href="" data-toggle="modal">
                                {{ saving.fortnight12 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight12 | currency }}
                            </span>
                          </span>
                          <br />
                          <span [ngSwitch]="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar')">
                            <span *ngSwitchCase="true">
                              <a (click)="showConfirmChangeFortnightSaving( saving, 24, saving.fortnight24 )" href="" data-toggle="modal">
                                {{ saving.fortnight24 | currency }}
                              </a>
                            </span>
                            <span *ngSwitchDefault>
                              {{ saving.fortnight24 | currency }}
                            </span>
                          </span>
                        </td>
                        <td>
                          {{ saving.amount | currency }}
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </fieldset>

        <div class="form-group text-center ">
          <button [disabled]="!validData() || sendingData" class="btn "
            [ngClass]="{ 'btn-outline-success': title=='Agregar' , 'btn-outline-warning': title=='Editar' } ">
            <i [ngClass]="{ 'far fa-save': !sendingData, 'fas fa-sync-alt fa-spin': sendingData } "></i>
            <span *ngIf="!sendingData "> Guardar</span>
            <span *ngIf="sendingData "> Guardando...</span>
          </button>
        </div>
      </div>
    </form>

    <table *ngIf="!haveTemporal" class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Año</th>
          <th scope="col">Qna 1<br />Qna 13</th>
          <th scope="col">Qna 2<br />Qna 14</th>
          <th scope="col">Qna 3<br />Qna 15</th>
          <th scope="col">Qna 4<br />Qna 16</th>
          <th scope="col">Qna 5<br />Qna 17</th>
          <th scope="col">Qna 6<br />Qna 18</th>
          <th scope="col">Qna 7<br />Qna 19</th>
          <th scope="col">Qna 8<br />Qna 20</th>
          <th scope="col">Qna 9<br />Qna 21</th>
          <th scope="col">Qna 10<br />Qna 22</th>
          <th scope="col">Qna 11<br />Qna 23</th>
          <th scope="col">Qna 12<br />Qna 24</th>
        </tr>
      </thead>

      <tbody>
        <tr class="text-right" *ngFor="let item of financialLiquidity; let i = index">
          <td>{{ item.year }}</td>
          <td>{{ item.fortnight1 | currency }}<br />{{ item.fortnight13 | currency }}</td>
          <td>{{ item.fortnight2 | currency }}<br />{{ item.fortnight14 | currency }}</td>
          <td>{{ item.fortnight3 | currency }}<br />{{ item.fortnight15 | currency }}</td>
          <td>{{ item.fortnight4 | currency }}<br />{{ item.fortnight16 | currency }}</td>
          <td>{{ item.fortnight5 | currency }}<br />{{ item.fortnight17 | currency }}</td>
          <td>{{ item.fortnight6 | currency }}<br />{{ item.fortnight18 | currency }}</td>
          <td>{{ item.fortnight7 | currency }}<br />{{ item.fortnight19 | currency }}</td>
          <td>{{ item.fortnight8 | currency }}<br />{{ item.fortnight20 | currency }}</td>
          <td>{{ item.fortnight9 | currency }}<br />{{ item.fortnight21 | currency }}</td>
          <td>{{ item.fortnight10 | currency }}<br />{{ item.fortnight22 | currency }}</td>
          <td>{{ item.fortnight11 | currency }}<br />{{ item.fortnight23 | currency }}</td>
          <td>{{ item.fortnight12 | currency }}<br />{{ item.fortnight24 | currency }}</td>
        </tr>
      </tbody>
    </table>

    <div>
      <a class="btn btn-outline-info " [routerLink]="[returnPath, searchValue] "><i class="fas fa-arrow-left "></i>
        Regresar</a>
    </div>
  </div>

    <ng-template appOpen></ng-template>
</div>
