import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { AccountService } from '../../account/login/services/account.service';
import { LoanInterface } from './interfaces/loan.interface';
import { DrawService } from '../draws/services/draw.service';
import { TeacherService } from '../teachers/services/teacher.service';
import { LoanService } from './services/loan.service';
import { LoanParameterService } from '../../catalogs/loan-parameter/services/loan-parameter.service';
import { LoanParameterInterface } from '../../catalogs/loan-parameter/interfaces/loan-parameter.interface';
import { RegionService } from '../../catalogs/regions/services/region.service';
import { RegionInterface } from '../../catalogs/regions/interfaces/region.interface';
import { FinancialLiquidityService } from '../financial-liquidity/services/financial-liquidity.service';
import { FinancialLiquidityInterface } from '../financial-liquidity/interfaces/financial-liquidity.interface';
import { BankInterface } from '../banks/interfaces/bank.interface';
import { BankService } from '../banks/services/bank.service';
import { SavingReportService } from '../../reports/savings/services/saving-report.service';
import { OpenDirective } from '../../modals/open.directive';
import { PaymentDTOInterface } from '../payments/interfaces/paymentDTO.interface';
import { PaymentService } from '../payments/services/payment.service';
import { SavingService } from '../savings/services/saving.service';
import { CurrencyPipe } from '@angular/common';
import { SavingRefinancingInterface } from '../savings/interfeces/saving-refinancing.interface';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { PaymentInterface } from '../payments/interfaces/payment.interface';
import { SavingInterface } from '../savings/interfeces/saving.interface';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { InputTypes } from '../../../../app/enums/sweetalert.enum';

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styles: []
})
export class LoanComponent implements OnInit {
  title = 'Agregar';
  haveAdmission = false;
  editing = false;
  returnPath = '/administration/loans';
  forma: UntypedFormGroup;
  // message: string;
  LoadingData = false;
  sendingData = false;
  amount = 0;
  amountInterests = 0;
  amountSavings = 0;
  banks = Array<BankInterface>();
  movementTypes = ['Cheque', 'Transferencia'];
  searchValue = '';
  loanParameters: LoanParameterInterface[] = [];
  prefixesParameters: LoanParameterInterface[] = [];
  fortnightsStart: string[] = [];
  fortnightsEnd: string[] = [];
  numberFortnights: number;
  regions: RegionInterface[] = [];
  financialLiquidity: FinancialLiquidityInterface[] = [];
  prefixes: Array<string> = [];
  loan: LoanInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    regionId: '00000000-0000-0000-0000-000000000000',
    bankId: '00000000-0000-0000-0000-000000000000',
    teacherStatusId: '00000000-0000-0000-0000-000000000000',
    date: new Date(),
    active: true,
    drawId: '',
    teacherId: '',
    loanParameterId: '',
   prefix: '',
    individualAccount: '',
    checkNumber: '0',
    amount: 0,
    discount: 0,
    interests: 0,
    comment: '',
    loanNumber: '',
    fortnightStart: '',
    fortnightEnd: '',
    status: 'Activo',
    openingBonus: 0,
    remainingFortnightly: 0,
    warranty: 0,
    applied: false,
    savingsPending: false,
    haveFinancialLiquidity: false,
    account: '',
    movementType: '',
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    draw: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: true,
      name: 'N/A',
      openDate: new Date(),
      closeDate: new Date(),
      status: '',
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      loans: []
    },
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      email: '',
      admission: '',
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      lastStatus: 'Activo',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      applied: true,
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      document: 0,
      documentNumber: '',
      NumberOfLoans: 0,
      supports: [],
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      lowStaffing: [],
      itIsSupplier: false
    },
    region: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date,
      active: true,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: 'N/A'
    },
    loanParameter: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      active: true,
      amount: 0,
      discount: 0,
      prefix: '',
      interests: 0,
      totalPerceptions: 0,
      saving: 0,
      guaranteedPercentage: 0,
      openingBonus: 0,
      fortnightId: '00000000-0000-0000-0000-000000000000',
      fortnight: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: true,
        numberFortnight: 0
      },
      fortnightCapital: 0,
      fortnightIVA: 0,
      fortnightInterest: 0,
      fortnightLifeInsurance: 0,
      iva: 0,
      lifeInsurance: 0,
      type: '',
      teachers: '',
      accountingConcept: ''
    },
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: 'Prueba',
      code: '00'
    },
    teacherStatus: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date,
      active: true,
      name: 'Activo',
      percentageSaving: 0,
      lastUpdated: new Date,
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000'
    },
    acceptanceDate: new Date(),
    protectionPremium: 0,
    block: 0,
    questions: [],
    payments: [],
    settlements: [],
    settlementsSaving: [],
    settlementsTotal: 0,
    settlementsSavingTotal: 0,
    settlementsCapital: 0,
    settlementsInterest: 0,
    settlementsLifeInsurance: 0,
    settlementsLifeCondoneLifeInsurance: 0,
    settlementsCondoneInterest: 0
  };
  indexFortnightsStart = 0;
  loanPending: any[] = [];
  showPayments = false;
  showSavings = false;
  haveTemporal = false;
  applications: ApplicationInterface[];
  loansSettlements: LoanInterface[];

  paymentForm: UntypedFormGroup;
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  indexPendings = 0;
  paymentsDTO: Array<PaymentDTOInterface> = [];
  balance = 0;
  grandTotalBalance = 0;
  deposit = 0;
  requiredSavings = 0;
  settlementId = '';


  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;

  constructor( public accountService: AccountService, private formBuilder: UntypedFormBuilder,
    private router: Router, private activatedRoute: ActivatedRoute,
    private drawService: DrawService, private loanParameterService: LoanParameterService,
    private teacherService: TeacherService, private loanService: LoanService,
    private regionService: RegionService, private financialLiquidityService: FinancialLiquidityService,
    private bankService: BankService,
    private reportService: SavingReportService,
      private paymentService: PaymentService,
      private savingService: SavingService,
      private currencyPipe: CurrencyPipe,
    private notificationService: NotificationService,
    private helperService: HelperService) {
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      drawId: ['', [Validators.required]],
      loanParameterId: ['', [Validators.required]],
      prefix: [{ value: '', disabled: this.editing  }, [Validators.required]],
      individualAccount: [''],
      checkNumber: [''],
      amount: ['', [Validators.required]],
      discount: ['', [Validators.required]],
      interests: ['', [Validators.required]],
      comment: [''],
      loanNumber: [{ value: '', disabled: this.editing  }, [Validators.required]],
      fortnightStart: ['', [Validators.required]],
      fortnightEnd: ['', [Validators.required]],
      status: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      teacherAdmission: ['', [Validators.required]],
      haveFinancialLiquidity: [''],
      account: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      clabe: ['', [Validators.minLength(18), Validators.maxLength(18)]],
      movementType: ['', [Validators.required]],
      bankId: ['', [Validators.required]],
      teacherStatusId: ['', [Validators.required]],
      street: [''],
      number: [''],
      colony: [''],
      zipCode: ['', [Validators.minLength(5), Validators.maxLength(5)]],
      email: [''],
      phone: ['', Validators.maxLength(20)],
      protectionPremium: ['']
    });
  }

  buildPaymentForm(): void {
    this.paymentForm = this.formBuilder.group({
      year: [new Date().getFullYear() , [Validators.required]],
      fortnight: [''],
      amount: ['', [Validators.required, Validators.min(0)]],
      interest: ['0', [Validators.required, Validators.min(0)]],
      condoneInterest: [{ value: '0.00', disabled: true }, [Validators.required, Validators.min(0)]],
      capital: [{ value: '0', disabled: true }, [Validators.required, Validators.min(0)]],
      amountSaving: ['0', [Validators.required, Validators.min(0)]],
      lifeInsurance: [ '0', [Validators.required, Validators.min(0)]],
      condoneLifeInsurance: [{ value: '0.00', disabled: true }, [Validators.required, Validators.min(0)]],
      payments: this.formBuilder.array([]),
      condonFortnight:['2']
    });
  }

  ngOnInit() {
    this.LoadingData = true;
    this.buildForm();
    this.buildPaymentForm();
    this.getYears();

    this.drawService.getOpen()
      .subscribe(
        draw => {
          if (draw) {
            this.loan.draw = draw;
            this.loan.drawId = draw.id;
            this.regionService.get('null')
              .subscribe(
                regions => {
                  this.regions = regions;
                  this.fortnightsStart = this.getFortnights(30);
                  this.fortnightsEnd = this.getFortnights(110);
                  this.bankService.get('null').subscribe( banks => {
                    this.banks = banks;
                    this.activatedRoute.params.subscribe(params => {
                      if ( params['returnPath'] ) {
                        this.returnPath = params['returnPath'];
                      }

                      if (params['id']) {
                        this.title = 'Editar';
                        this.editing = true;
                        this.loanService.getById( params['id'] )
                          .subscribe(data => {
                            this.loan = data;
                            this.loanParameterService
                              .GetLoanParameterByTeacher(this.loan.teacher.type)
                              .subscribe(
                                (parameters) => {
                                  this.loanParameters = parameters;
                                  this.loan.teacher = data.teacher;
                                  this.financialLiquidityService.getLastTwoYears(this.loan.teacher.id)
                                  .subscribe(financialLiquidity => {
                                    this.financialLiquidity = financialLiquidity;
                                    this.accountService.getApplications('administration', 'loans')
                                    .subscribe(applications => {
                                      this.applications = applications;
                                      if (this.loan.teacher.admission) {
                                        this.haveAdmission = true;
                                      }
                                      this.prefixes = Array.from(new Set(this.loanParameters.map((item: any) => item.prefix)));
                                      this.loansSettlements = this.getSettlements(this.loan.settlements);
                                      this.loansSettlements.forEach(loan => {
                                        loan.payments = this.loan.settlements.filter(x => x.loanId === loan.id);
                                      });
                                      this.grandTotalBalance = this.loan.settlementsTotal;
                                      this.requiredSavings = this.loan.settlementsSavingTotal;
                                      this.setValues(this.loan);
                                      this.LoadingData = false;
                                    }, (error) => this.errorMessage(error));
                                  }, error => this.errorMessage(error));
                                }, (error) => this.errorMessage(error));
                          }, error => this.errorMessage(error));
                      } else if (params['teacher']) {
                        this.loan.fortnightStart = this.fortnightsStart[0];
                        this.loan.teacherId = params['teacher'];
                        this.teacherService.getById(params['teacher'])
                          .subscribe(teacher => {
                            this.loan.regionId = teacher.regionId;
                            this.loan.teacher = teacher;
                            var temporalLoan = teacher.loans.find(x => x.status === 'Temporal' || x.status === 'Validado');
                            if(temporalLoan) {
                              this.haveTemporal = true;
                              this.showSavings = temporalLoan.savingsPending;
                              this.requiredSavings = temporalLoan.loanParameter.saving;
                              this.settlementId = temporalLoan.id;
                            }
                            this.getData(this.loan.teacher.rfc);
                            this.financialLiquidityService.getLastTwoYears(teacher.id)
                              .subscribe(financialLiquidity => {
                                this.financialLiquidity = financialLiquidity;
                                if (this.loan.teacher.admission) {
                                  this.haveAdmission = true;
                                }

                                this.loanParameterService.GetLoanParameterByTeacher(teacher.type)
                                  .subscribe(parameters => {
                                    this.loanParameters = parameters;
                                   this. prefixes = Array.from(new Set(this.loanParameters.map((item: any) => item.prefix)));
                                    this.setValues(this.loan);
                                    this.teacherService.getLoansPendings(this.loan.teacherId)
                                    .subscribe(pending => {
                                      if(pending.length > 0) {
                                        this.loanPending = pending;
                                        this.showPayments = true;
                                        this.setValuesPaymentForm();
                                        this.EvaluateGrandTotalBalance();
                                        this.loan.status = 'Temporal';
                                      }
                                      this.LoadingData = false;
                                    }, error => this.errorMessage(error));
                                  }, error => this.errorMessage(error));
                              }, error => this.errorMessage(error));
                          }, error => this.errorMessage(error));
                      }
                    });
                  }, error => this.errorMessage(error));
                }, error => this.errorMessage(error));
          } else {
            this.notificationService.showErrorMessage('No se encuentra ningún sorteo abierto.');
            this.LoadingData = false;
          }
        },
        error => this.errorMessage(error)
      );
  }

  getSettlements(payments: PaymentInterface[]): LoanInterface[] {
    let arrRetrun: LoanInterface[] = [];
    payments.forEach(payment => {
      if(!arrRetrun.some(x => x.id)) {
        const loan = payment.loan;
        loan.settlementsCapital = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.capital, 0);
        loan.settlementsInterest = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.interest, 0);
        loan.settlementsCondoneInterest = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.condoneInterest, 0);
        loan.settlementsLifeInsurance = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.lifeInsurance, 0);
        loan.settlementsLifeCondoneLifeInsurance = payments.filter(pay => pay.loanId === loan.id).reduce((sum, current) => sum + current.condoneLifeInsurance, 0);
        arrRetrun.push(loan);
      }
    });
    return arrRetrun;
  }

  getYears(): void {
    for (let index = new Date().getFullYear() - 18; index < new Date().getFullYear() + 5; index++) {
      this.years.push(index);
    }
  }

  setValues( loan: LoanInterface ): void {
    this.forma.controls['drawId'].patchValue( loan.drawId );
    this.forma.controls['prefix'].patchValue( loan.loanParameter.prefix );
    if( this.editing ) {
      this.prefixesParameters = this.loanParameters.filter(x => x.prefix == this.forma.controls['prefix'].value );
      this.setParameters( loan.loanParameterId );
    }
    this.forma.controls['loanParameterId'].patchValue( loan.loanParameterId );
    this.forma.controls['individualAccount'].patchValue( loan.individualAccount );
    this.forma.controls['checkNumber'].patchValue( loan.checkNumber );
    this.forma.controls['amount'].patchValue( loan.amount );
    this.forma.controls['discount'].patchValue( loan.discount );
    this.forma.controls['interests'].patchValue( loan.interests );
    this.forma.controls['comment'].patchValue( loan.comment );
    this.forma.controls['loanNumber'].patchValue( loan.loanNumber.split('-')[1] );
    this.forma.controls['fortnightStart'].patchValue( loan.fortnightStart );
    this.forma.controls['fortnightEnd'].patchValue( loan.fortnightEnd );
    this.forma.controls['status'].patchValue( loan.status );
    this.forma.controls['regionId'].patchValue( loan.teacher.regionId );
    this.forma.controls['teacherAdmission'].patchValue( loan.teacher.admission );
    this.forma.controls['haveFinancialLiquidity'].patchValue( loan.haveFinancialLiquidity );

    if ( loan.account !== '' ) {
      if (loan.account.length === 10 ) {
        this.forma.controls['account'].patchValue( loan.account );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      } else if ( loan.account.length > 10 ) {
        this.forma.controls['clabe'].patchValue( loan.account );
        this.forma.controls['account'].patchValue( '0000000000' );
      } else if ( loan.teacher.clabe.length === 10 ) {
        this.forma.controls['account'].patchValue( loan.teacher.clabe );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      } else if ( loan.teacher.clabe.length > 10 ) {
        this.forma.controls['clabe'].patchValue( loan.teacher.clabe );
        this.forma.controls['account'].patchValue( '0000000000' );
      } else {
        this.forma.controls['clabe'].patchValue( '' );
        this.forma.controls['account'].patchValue( '' );
      }
    } else if (this.editing) {
      this.forma.controls['clabe'].patchValue( '000000000000000000' );
      this.forma.controls['account'].patchValue( '0000000000' );
    } else {
      this.forma.controls['clabe'].patchValue( '' );
      this.forma.controls['account'].patchValue( '' );
    }

    this.forma.controls['movementType'].patchValue( loan.movementType );

    if(this.editing && loan.movementType === 'Transferencia') {
      if ( this.loan.bankId !== null ) {
        this.forma.controls['bankId'].patchValue( loan.bankId );
      } else if ( loan.teacher.bankId ) {
        this.forma.controls['bankId'].patchValue( loan.teacher.bankId );
      } else {
        this.forma.controls['bankId'].patchValue( '00000000-0000-0000-0000-000000000000' );
      }
    } else {
      if ( loan.teacher.bankId ) {
        this.forma.controls['bankId'].patchValue( loan.teacher.bankId );
      } else {
        this.forma.controls['bankId'].patchValue( '' );
      }
    }
    
    this.forma.controls['teacherStatusId'].patchValue( loan.teacher.teacherStatus.find(t => t.active === true).id );
    this.forma.controls['street'].patchValue( loan.teacher.street );
    this.forma.controls['number'].patchValue( loan.teacher.number );
    this.forma.controls['colony'].patchValue( loan.teacher.colony );
    this.forma.controls['zipCode'].patchValue( loan.teacher.zipCode );
    this.forma.controls['email'].patchValue( loan.teacher.email );
    this.forma.controls['phone'].patchValue( (loan.teacher.teacherPhones.length === 0 ) ? '' : loan.teacher.teacherPhones[0].phone );
    this.forma.controls['protectionPremium'].patchValue( loan.protectionPremium );
  }

  setFormatCheck(value: string): string {
    let checkWithFormat = value.toString();
    while (checkWithFormat.length < 7) {
      checkWithFormat = `0${ checkWithFormat }`;
    }
    return checkWithFormat;
  }

  sendForm(): void {
    this.sendingData = true;
    this.getValues(this.loan);
    this.loan.date = new Date();
    if ( this.title === 'Agregar') {
      this.loanService.create(this.loan)
        .subscribe(loan => {
          this.loan = loan;
          if(this.loanPending.length > 0) {
            this.settlementId = this.loan.id;
            this.showPayments = true;
            this.haveTemporal = true;
            this.sendingData = false;
          } else if (this.showSavings) {
            const refinancing: SavingRefinancingInterface = {
              teacherId: this.loan.teacher.id,
              userId: this.accountService.getUserId(),
              amount: parseFloat((this.requiredSavings - this.amountSavings).toFixed(2)),
              settlementId: this.loan.id
            };
            this.savingService.Refinancing(refinancing)
            .subscribe(() => {
              this.router.navigate([this.returnPath, this.searchValue]);
            })
          } else {
            this.router.navigate([this.returnPath, this.searchValue]);
          }
        }, error => this.errorMessage(error));
    } else {
      this.loanService.update(this.loan.id, JSON.parse(this.stringify(this.loan)))
            .subscribe(() => {
              this.router.navigate([this.returnPath, this.searchValue]);
            }, error => this.errorMessage(error));
    }
  }

  getValues( loan: LoanInterface ) {
    loan.drawId = this.forma.controls['drawId'].value;
    loan.loanParameterId = this.forma.controls['loanParameterId'].value;
    loan.individualAccount = this.forma.controls['individualAccount'].value;
    loan.checkNumber = this.forma.controls['checkNumber'].value;
    loan.amount = this.forma.controls['amount'].value;
    loan.discount = this.forma.controls['discount'].value;
    loan.interests = this.forma.controls['interests'].value;
    loan.comment = this.forma.controls['comment'].value;
    loan.loanNumber = this.forma.controls['prefix'].value + '-' + this.forma.controls['loanNumber'].value;
    loan.fortnightStart = this.forma.controls['fortnightStart'].value;
    loan.fortnightEnd = this.forma.controls['fortnightEnd'].value;
    // loan.status = (this.loanPending.length > 0) ? 'Temporal' : 'Activo';
    loan.regionId = this.forma.controls['regionId'].value;
    loan.haveFinancialLiquidity = this.forma.controls['haveFinancialLiquidity'].value;
    loan.teacherStatusId = this.forma.controls['teacherStatusId'].value;
    loan.movementType = this.forma.controls['movementType'].value;
    loan.savingsPending = this.showSavings;
    // Cheque
    if ( this.forma.controls['movementType'].value === this.movementTypes[0] ) {
      loan.account = '';
    } else {
      loan.bankId = this.forma.controls['bankId'].value;
      if ( this.forma.controls['bankId'].value !== 'be28ff92-e845-41bb-8bee-3060267a7023' ) {
        loan.account = this.forma.controls['clabe'].value;
      } else {
        loan.account = this.forma.controls['account'].value;
      }
    }
    this.loan.teacher.street = this.forma.controls['street'].value;
    this.loan.teacher.number = this.forma.controls['number'].value;
    this.loan.teacher.colony = this.forma.controls['colony'].value;
    this.loan.teacher.zipCode = this.forma.controls['zipCode'].value;
    this.loan.teacher.email = this.forma.controls['email'].value;
    if ( this.forma.controls['phone'].value !== '' ) {
      this.loan.teacher.teacherPhones.push({
        id: '00000000-0000-0000-0000-000000000000',
        userId: this.accountService.getUserId(),
        teacherId: this.loan.teacher.id,
        date: new Date(),
        active: true,
        lastUpdated: new Date,
        phoneTypeId: '176BF9E8-E83F-428D-AD19-0CBAECDD40AB',
        userIdLastUpdated: this.accountService.getUserId(),
        phone: this.forma.controls['phone'].value
      });
    }
  }

  getFortnights(numberFortnights: number): string[] {
    const arrFortnight = [];
    let counter = 1;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        arrFortnight.push( date.getFullYear().toString() + '-' + this.setFormatDay(numberFortnight) );
        counter++;
      }
      numberFortnight++;
      if ( numberFortnight === 25 ) {
        numberFortnight = 1;
      }
      this.getDateFortnight(date);
    } while ( counter <= numberFortnights );
    return arrFortnight;
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
      date.setDate( date.getDate() - 1 );
    } else {
      date.setDate( date.getDate() + 15 );
    }
  }

  setFormatDay(day: number): string {
    if ( day.toString().length === 1 ) {
      return '0' + day.toString();
    }
    return day.toString();
  }

  changeParameter( ): void {
    const fortnightId = this.forma.controls['loanParameterId'].value;
    this.setParameters(fortnightId);
  }

  setPrefixParameter( ) {
    this.prefixesParameters = this.loanParameters.filter(x => x.prefix == this.forma.controls['prefix'].value );
    this.resetLoan();
  }

  handlerCHangeMovement( ) {
    if ( this.forma.controls['movementType'].value === 'Cheque' ) {
      this.forma.controls['bankId'].patchValue( '00000000-0000-0000-0000-000000000000' );
      this.forma.controls['account'].patchValue( '0000000000' );
      this.forma.controls['clabe'].patchValue( '000000000000000000' );
    } else {
      this.forma.controls['bankId'].patchValue( this.loan.teacher.bankId );
      if ( this.loan.teacher.clabe.length === 10 ) {
        this.forma.controls['account'].patchValue( this.loan.teacher.clabe );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      } else if ( this.loan.teacher.clabe.length > 10 ) {
        this.forma.controls['clabe'].patchValue( this.loan.teacher.clabe );
        this.forma.controls['account'].patchValue( '0000000000' );
      } else {
        this.forma.controls['clabe'].patchValue( '' );
        this.forma.controls['account'].patchValue( '' );
      }
    }
  }

  handlerBanks() {
    if ( this.forma.controls['bankId'].value === 'be28ff92-e845-41bb-8bee-3060267a7023' ) {
      if ( this.loan.teacher.clabe.length === 10 ) {
        this.forma.controls['account'].patchValue( this.loan.teacher.clabe );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      } else {
        this.forma.controls['account'].patchValue( '' );
        this.forma.controls['clabe'].patchValue( '000000000000000000' );
      }
    } else {
      if ( this.loan.teacher.clabe.length > 10 ) {
        this.forma.controls['clabe'].patchValue( this.loan.teacher.clabe );
        this.forma.controls['account'].patchValue( '0000000000' );
      } else {
        this.forma.controls['account'].patchValue( '0000000000' );
        this.forma.controls['clabe'].patchValue( '' );
      }
    }
  }

  setParameters(id: string): void {
    let reset = true;
    this.loanParameters.forEach(parameter => {
      if ( parameter.id === id ) {
        if ( !this.editing ) {
          this.loan.discount = parameter.discount;
          this.loan.interests = parameter.interests;
          this.loan.amount = parameter.amount - parameter.openingBonus - (parameter.amount * (parameter.guaranteedPercentage / 100));
          this.loan.openingBonus = parameter.openingBonus;
          this.loan.warranty = parameter.amount * (parameter.guaranteedPercentage / 100);
          this.loan.remainingFortnightly = parameter.fortnight.numberFortnight;
          this.loan.protectionPremium = parameter.lifeInsurance;
          this.loan.status = this.evaluteLoan(parameter);
          this.loan.teacher.credit = parameter.amount;
        }
        this.numberFortnights = parameter.fortnight.numberFortnight - 1;
        this.forma.controls['fortnightEnd'].patchValue( this.fortnightsEnd[this.numberFortnights + this.indexFortnightsStart] );
        this.forma.controls['interests'].patchValue( parameter.interests );
        this.forma.controls['discount'].patchValue( parameter.discount );
        this.forma.controls['amount'].patchValue( parameter.amount );
        this.forma.controls['comment'].patchValue( this.loan.comment );
        this.forma.controls['status'].patchValue( this.loan.status );
        this.forma.controls['protectionPremium'].patchValue( this.loan.status );
        this.deposit = parameter.amount - (this.grandTotalBalance + parameter.openingBonus + (parameter.amount * (parameter.guaranteedPercentage / 100) + (this.requiredSavings - this.amountSavings)))
        reset = false;
      }
    });
    if ( reset ) {
      this.resetLoan();
    }
  }

  resetLoan(): void {
    this.loan.discount = 0;
    this.loan.interests = 0;
    this.loan.openingBonus = 0;
    this.loan.warranty = 0;
    this.loan.remainingFortnightly = 0;
    this.forma.controls['fortnightEnd'].patchValue( '' );
    this.forma.controls['interests'].patchValue( 0 );
    this.forma.controls['amount'].patchValue( 0 );
    this.forma.controls['discount'].patchValue( 0 );
    this.forma.controls['status'].patchValue( '' );
    this.forma.controls['comment'].patchValue( '' );
    this.deposit = 0.00;
  }

  evaluteLoan( loanParameter: LoanParameterInterface ): string {
    let status = this.loan.status;
    this.showSavings = false;
    this.loan.savingsPending = false;
    this.getData(this.loan.teacher.rfc);
    if ( parseFloat(this.amountSavings.toString()) < parseFloat(loanParameter.saving.toString()) ) {
      this.showSavings = true;
      this.requiredSavings = loanParameter.saving
      this.loan.savingsPending = true;
      status = 'Temporal'
    }

    if ( !this.loan.teacher.birthdate && !this.loan.teacher.admission ) {
      this.notificationService.showErrorMessage('El maestro no cuenta con las fechas para el cálculo.');
    }

    if ( this.loan.haveFinancialLiquidity.toString() === 'false' ) {
      this.notificationService.showErrorMessage('El maestro no cuenta con la suficiente liquidez financiera.');
    }

    return status;
  }

  getData(rfc: string) {
    this.reportService.get( rfc )
      .subscribe(( data ) => {
        this.calculateAmount( data );
      }, error => this.notificationService.showErrorMessage(error));
  }

  calculateAmount(data: any[]) {
    this.amountSavings = 0;
    let interestWithImport = 0;
    let teacherRFC = '';

    data.forEach(year => {
      if ( year.rfc !== teacherRFC ) {
        teacherRFC = year.rfc;
        interestWithImport = 0;
      }
      let interestPeritem = 0;
      const arrayYear = Object.keys(year).map(function(key) {
        return [String(key), year[key]];
      });
      for (let index = 0; index < arrayYear.length; index++) {
        if ( arrayYear[index][0].slice(0, 3).toUpperCase() === 'QNA' ) {
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * 0.00208;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
          }
        }
      }
      year.interests = parseFloat(interestPeritem.toFixed(2));
      this.amountInterests += year.interests;
      this.amountSavings += (year.interests + year.total);
    });

    if(this.showSavings && !this.showPayments) {
      this.amount = parseFloat((this.requiredSavings - this.amountSavings).toFixed(2));
    }
  }

  blurAdmission( value: string ): void {
    if ( value ) {
      this.loan.teacher.admission = value;
      this.forma.controls['teacherAdmission'].patchValue( this.loan.teacher.admission );
      this.changeParameter();
    }
  }

  getNumberFortnight(id: string): number {
    let numberFortnight = 0;
    this.loanParameters .forEach(parameter => {
      if ( parameter.id === id ) {
        numberFortnight = parameter.fortnight.numberFortnight;
      }
    });
    return numberFortnight;
  }

  errorMessage( error: any ) {
    console.error(error);
    if (error && error.error) {
      this.notificationService.showErrorMessage(error.message);
      this.LoadingData = false;
      this.sendingData = false;
    }
  }

  changeFortnightStart(): void {
    // tslint:disable-next-line: max-line-length
    this.indexFortnightsStart = this.getGetIndex(this.fortnightsStart, this.forma.controls['fortnightStart'].value);    if ( this.loan.amount !== 0 ) {
      this.forma.controls['fortnightEnd'].patchValue( this.fortnightsEnd[this.numberFortnights + this.indexFortnightsStart] );
    }
  }
  getGetIndex(array: any[], value: string): number {
    const BreakException = { };
    let index = 0;
    try {
      array.forEach(element => {
        if ( element === value ) {
          throw BreakException;
        }
        index += 1;
      });
    } catch (e) {
      if (e !== BreakException) { throw e; }
    }
    return index;
  }

  async showChangeStatus(  ) {
    const credentials = await this.notificationService.showConfirmAuthentication('¿Seguro que desea cambiar el estatus del prestamos?');
    if(credentials !== null) {
      this.canChangeStatus(credentials);
    }
  }

  canChangeStatus(userInfo: any ) {
    this.accountService.postCheckChangeStatus(userInfo)
    .subscribe(( ) => {
      this.loan.status = 'Activo';
      this.forma.controls['status'].patchValue( this.loan.status );
    }, error => {
      this.notificationService.showErrorMessage(error.error[''][0]);
    });
  }

  validData(): boolean {
    if ( !this.forma.valid ) {
      return false;
    }

    if(this.forma.controls['haveFinancialLiquidity'].value === false){
      return false;
    }

    if ( this.forma.controls['movementType'].value === 'Transferencia' ) {
      if ( this.forma.controls['bankId'].value === '00000000-0000-0000-0000-000000000000' ) {
        return false;
      } else {
        if ( this.forma.controls['bankId'].value === 'be28ff92-e845-41bb-8bee-3060267a7023' &&
        this.forma.controls['account'].value === '' ) {
          return false;
        } else if ( this.forma.controls['bankId'].value !== 'be28ff92-e845-41bb-8bee-3060267a7023' &&
        this.forma.controls['clabe'].value === '' ) {
          return false;
        }
      }
    }

    return true;
  }

  setValuesPaymentForm() {
    this.amount = 0.00;
    let fortnightStart = this.loanPending[this.indexPendings].fortnightStart.split('-');
    let fortnightEnd = this.loanPending[this.indexPendings].fortnightEnd.split('-');
    const isOldLoan = this.isOldLoan(parseInt(`${fortnightEnd[0]}${fortnightEnd[1]}`));
    const condoneFortnight = (isOldLoan) ? 0 : 2;
    this.paymentForm.controls['amount'].patchValue(this.loanPending[this.indexPendings].amount);
    this.evaluateFortnightIncomplete();
    this.evaluateFortnightPendings(parseInt(fortnightStart[0]), parseInt(fortnightEnd[0]), parseInt(fortnightStart[1]), parseInt(fortnightEnd[1]), condoneFortnight);
    if(isOldLoan) {
      this.paymentForm.controls['interest'].disable();
      this.paymentForm.controls['lifeInsurance'].disable();
      this.paymentForm.controls['condonFortnight'].disable();
      this.paymentForm.controls['condonFortnight'].patchValue('0');
      this.evaluatePendingLoanValues(condoneFortnight);
    } else {
      this.paymentForm.controls['interest'].enable();
      this.paymentForm.controls['lifeInsurance'].enable();
      this.paymentForm.controls['condonFortnight'].enable();
      this.evaluatePendingLoanValues(condoneFortnight);
    }
  }

  evaluateFortnightIncomplete(): void {
    if( this.loanPending[this.indexPendings].incompleteFortnights) {
      const arrFortnights = this.loanPending[this.indexPendings].incompleteFortnights.split(',');
      arrFortnights.forEach(fortnight => {
        if(fortnight !== '') {
          const arrValues = fortnight.split('-');
          let amountPayment = parseFloat(this.loanPending[this.indexPendings].amount) - parseFloat(arrValues[2]);
          const beneficiariesFG = this.BuildPayment(parseFloat(amountPayment.toFixed(2)), parseInt(arrValues[0]), parseInt(arrValues[1]));
          this.payments.push(beneficiariesFG);
          this.amount += amountPayment;
        }
      });
    }
  }

  evaluateFortnightPendings(yearStart: number, yearEnd: number, fortnightStart: number, fortnightEnd: number, condoneFortnight: number): void {
    for (let year = yearStart; year <= yearEnd; year++) {
      let fortnightS = 1;
      let fortnightE = 24;
      if(year === yearStart) {
        fortnightS = parseInt(fortnightStart.toString()) + parseInt(condoneFortnight.toString());
      }

      if(year === yearEnd) {
        fortnightE = fortnightEnd;
      }

      for (let index = fortnightS; index <= fortnightE; index++) {
        const beneficiariesFG = this.BuildPayment(this.loanPending[this.indexPendings].amount, year, index );
        this.payments.push(beneficiariesFG);
        this.amount += parseFloat(this.loanPending[this.indexPendings].amount);
      }
    }
  }

  addPayment() {
    if(!this.existFortnight(this.paymentForm.controls['year'].value, this.paymentForm.controls['fortnight'].value)) {
      const beneficiariesFG = this.BuildPayment(this.paymentForm.controls['amount'].value, this.paymentForm.controls['year'].value, this.paymentForm.controls['fortnight'].value);
      this.payments.push(beneficiariesFG);
      this.evaluateLoanValues();
    }
  }

  getNumberOfPayments( anioStart: number, fortnightStart: number, anioEnd: number, fortnightEnd: number ): number {
    const yearsDifference = anioEnd - anioStart;
    const fortnightsDifference = (fortnightEnd + 1) - fortnightStart;
    if ( yearsDifference === 0 ) {
      return fortnightsDifference;
    }
    return ((yearsDifference * 24) - (fortnightStart - 1)) + fortnightEnd;
  }

  BuildPayment( amount: number, year: number, fortnight: number ) {
    return this.formBuilder.group({
      year: year,
      fortnight: fortnight,
      amount: [amount, [Validators.required, Validators.min(0)]]
    });
  }

  blurPayment(): void {
    this.evaluateLoanValues();
  }

  getDepositAmount(fortnightToEvaluate: string): number {
    let deposit = 0;
    const startPosition = this.loanPending[this.indexPendings].incompleteFortnights.indexOf(fortnightToEvaluate);
    if(startPosition > -1) {
      const valuesStr = this.loanPending[this.indexPendings].incompleteFortnights.substring(startPosition, this.loanPending[this.indexPendings].incompleteFortnights.length);
      deposit = parseFloat(valuesStr.substring(8, valuesStr.indexOf(',')));
    }
    return deposit;
  }

  addPaymentForm(): void {
    this.notificationService.showConfirm('Seguro que desea agregar el movimiento.')
    .then((result) => {
      if (result.isConfirmed) {
        this.sendingData = true;
        if (this.amountPaymentValid()) {
          this.addPaymentList();
          this.paymentService.create( this.paymentsDTO )
          .subscribe(() => {
            if( (this.loanPending.length - 1) === this.indexPendings ) {
              this.showPayments = false;
              if( this.showSavings ) {
                const refinancing: SavingRefinancingInterface = {
                  teacherId: this.loan.teacher.id,
                  userId: this.accountService.getUserId(),
                  amount: parseFloat((this.requiredSavings - this.amountSavings).toFixed(2)),
                  settlementId: this.settlementId
                };
                this.savingService.Refinancing(refinancing)
                .subscribe(() => {
                  this.router.navigate([this.returnPath, this.searchValue]);
                })
              } else {
                this.router.navigate([this.returnPath, this.searchValue]);
              }
            } else if(this.indexPendings < this.loanPending.length) {
              this.indexPendings++;
              this.resetPaymentForm();
            }
            this.sendingData = false;
          }, error => this.setErrorMessage(error));
        } else {
          this.sendingData = false;
        }
      }
    });
  }

  amountPaymentValid(): boolean {
    if ( Math.trunc(this.balance) === Math.trunc( this.amount ) ) {
      return true;
    }
    if ( Math.trunc( this.amount ) < Math.trunc(this.balance) ) {
      this.notificationService.showErrorMessage(`El importe del acumulado ${ this.amount.toFixed(2) } no puede ser menos al importe del adeudo ${ this.balance.toFixed(2) }.`);
    }
    if ( Math.trunc( this.amount ) > Math.trunc(this.balance) ) {
      this.notificationService.showErrorMessage(`El importe del acumulado ${ this.amount.toFixed(2) } no puede ser mayor al importe del adeudo ${ this.balance.toFixed(2) }.`);
    }
    return false;
  }

  setErrorMessage( error: any ) {
    console.log(error);
    if (error && error.error) {
      this.notificationService.showErrorMessage(error.message);
      this.sendingData = false;
    }
  }

  addPaymentList(): void {
    let depositAmount = this.loanPending[this.indexPendings].amountSaving + this.loanPending[this.indexPendings].interest + this.loanPending[this.indexPendings].lifeInsurance;;
    let interest = this.paymentForm.controls['interest'].value;
    let condoneInterest = this.paymentForm.controls['condoneInterest'].value;
    let capital = this.paymentForm.controls['capital'].value;
    let lifeInsurance = this.paymentForm.controls['lifeInsurance'].value;
    let condoneLifeInsurance = this.paymentForm.controls['condoneLifeInsurance'].value;
    let condoneFortnight = this.paymentForm.controls['condonFortnight'].value;

    for (let index = 0; index < this.payments.length; index++) {
      const payment = this.payments.at(index) as UntypedFormGroup;

      if( index > 0 ) {
        depositAmount = 0;
        interest = 0;
        condoneInterest = 0;
        capital = 0;
        lifeInsurance = 0;
        condoneLifeInsurance = 0;
        condoneFortnight = 0;
      }

      if ( this.existYear(
        payment.controls['year'].value,
        'Temporal' ) ) {
        this.updateFortnight(
          parseFloat(payment.controls['year'].value),
          payment.controls['fortnight'].value,
          payment.controls['amount'].value,
          'Temporal' );
      } else {
        this.addElementToList(
          parseFloat(payment.controls['year'].value),
          payment.controls['fortnight'].value,
          payment.controls['amount'].value,
          interest,
          condoneInterest,
          capital,
          lifeInsurance,
          condoneLifeInsurance,
          condoneFortnight);
      }
    }

    if(this.payments.length === 0 && this.balance === 0) {
      this.addElementToList(9999, 1, 0, 0, 0, 0, 0, 0, 0);
    }
  }

  existYear(year: number, type: string): boolean {
    const result = this.paymentsDTO.filter(p => p.year === year && p.type === type );
    if ( result.length > 0 ) {
      return true;
    }
    return false;
  }

  addElementToList(
    year: number,
    fortnight: number,
    amount: number,
    interest: number,
    condoneInterest: number,
    capital: number,
    lifeInsurance: number,
    condoneLifeInsurance: number,
    condoneFortnight: number): void {
    const payment: PaymentDTOInterface = {
      userId: this.accountService.getUserId(),
      loanId: this.loanPending[this.indexPendings].loan.id,
      teacherId: this.loanPending[this.indexPendings].teacher.id,
      type: this.loanPending[this.indexPendings].type,
      movementDate: `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-${ this.formatDate(new Date().getDate()) }`,
      folio: this.loanPending[this.indexPendings].folio,
      amount: 0,
      applyFortnight: '',
      amountSaving: 0,
      year: year,
      interest: interest,
      fortnight1: 0,
      fortnight2: 0,
      fortnight3: 0,
      fortnight4: 0,
      fortnight5: 0,
      fortnight6: 0,
      fortnight7: 0,
      fortnight8: 0,
      fortnight9: 0,
      fortnight10: 0,
      fortnight11: 0,
      fortnight12: 0,
      fortnight13: 0,
      fortnight14: 0,
      fortnight15: 0,
      fortnight16: 0,
      fortnight17: 0,
      fortnight18: 0,
      fortnight19: 0,
      fortnight20: 0,
      fortnight21: 0,
      fortnight22: 0,
      fortnight23: 0,
      fortnight24: 0,
      condoneInterest: condoneInterest,
      condoneFortnight: condoneFortnight,
      capital: capital,
      financialProducts: 0,
      lifeInsurance: lifeInsurance,
      condoneLifeInsurance: condoneLifeInsurance,
      settlementId: this.settlementId
    };

    this.SetFortnightValue(payment, fortnight.toString(), amount );
    this.paymentsDTO.push(payment);
  }

  updateFortnight(year: number, fortnight: number, amount: number, type: string): void {

    this.paymentsDTO.forEach(pay => {
      if ( pay.year === year && pay.type === type ) {
        switch ( fortnight.toString() ) {
          case '1':
              pay.fortnight1 = amount;
            break;
          case '2':
              pay.fortnight2 = amount;
            break;
          case '3':
              pay.fortnight3 = amount;
            break;
          case '4':
              pay.fortnight4 = amount;
            break;
          case '5':
              pay.fortnight5 = amount;
            break;
          case '6':
              pay.fortnight6 = amount;
            break;
          case '7':
              pay.fortnight7 = amount;
            break;
          case '8':
              pay.fortnight8 = amount;
            break;
          case '9':
              pay.fortnight9 = amount;
            break;
          case '10':
              pay.fortnight10 = amount;
            break;
          case '11':
              pay.fortnight11 = amount;
            break;
          case '12':
              pay.fortnight12 = amount;
            break;
          case '13':
              pay.fortnight13 = amount;
            break;
          case '14':
              pay.fortnight14 = amount;
            break;
          case '15':
              pay.fortnight15 = amount;
            break;
          case '16':
              pay.fortnight16 = amount;
            break;
          case '17':
              pay.fortnight17 = amount;
            break;
          case '18':
              pay.fortnight18 = amount;
            break;
          case '19':
              pay.fortnight19 = amount;
            break;
          case '20':
              pay.fortnight20 = amount;
            break;
          case '21':
              pay.fortnight21 = amount;
            break;
          case '22':
              pay.fortnight22 = amount;
            break;
          case '23':
              pay.fortnight23 = amount;
            break;
          case '24':
              pay.fortnight24 = amount;
            break;
        }
      }
    });
  }

  SetFortnightValue(payment: PaymentDTOInterface, fortnight: string, amount: number): void {
    switch ( fortnight ) {
      case '1':
        payment.fortnight1 = amount;
        break;
      case '2':
        payment.fortnight2 = amount;
        break;
      case '3':
        payment.fortnight3 = amount;
        break;
      case '4':
        payment.fortnight4 = amount;
        break;
      case '5':
        payment.fortnight5 = amount;
        break;
      case '6':
        payment.fortnight6 = amount;
        break;
      case '7':
        payment.fortnight7 = amount;
        break;
      case '8':
        payment.fortnight8 = amount;
        break;
      case '9':
        payment.fortnight9 = amount;
        break;
      case '10':
        payment.fortnight10 = amount;
        break;
      case '11':
        payment.fortnight11 = amount;
        break;
      case '12':
        payment.fortnight12 = amount;
        break;
      case '13':
        payment.fortnight13 = amount;
        break;
      case '14':
        payment.fortnight14 = amount;
        break;
      case '15':
        payment.fortnight15 = amount;
        break;
      case '16':
        payment.fortnight16 = amount;
        break;
      case '17':
        payment.fortnight17 = amount;
        break;
      case '18':
        payment.fortnight18 = amount;
        break;
      case '19':
        payment.fortnight19 = amount;
        break;
      case '20':
        payment.fortnight20 = amount;
        break;
      case '21':
        payment.fortnight21 = amount;
        break;
      case '22':
        payment.fortnight22 = amount;
        break;
      case '23':
        payment.fortnight23 = amount;
        break;
      case '24':
        payment.fortnight24 = amount;
        break;
    }
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  resetPaymentForm() {
    this.paymentsDTO = [];
    this.paymentForm.controls['year'].patchValue(new Date().getFullYear());
    this.paymentForm.controls['fortnight'].patchValue('');
    this.paymentForm.controls['yearEnd'].patchValue(new Date().getFullYear());
    this.paymentForm.controls['fortnightEnd'].patchValue('');
    this.paymentForm.controls['amount'].patchValue('0');
    this.paymentForm.controls['condonFortnight'].patchValue('2');
    this.amount = 0;
    this.setValuesPaymentForm();
    this.removePayments();
  }

  onBlurInterest(event: any): void{
    let amount = parseFloat(event.target.value);
    let interest = parseFloat(this.loanPending[this.indexPendings].interest) - parseFloat(((this.loanPending[this.indexPendings].loan.loanParameter.fortnightInterest + this.loanPending[this.indexPendings].loan.loanParameter.fortnightIVA ) * this.paymentForm.controls['condonFortnight'].value).toFixed(2));
    if(amount > interest) {
      this.paymentForm.controls['interest'].patchValue(interest.toFixed(2));
      this.paymentForm.controls['condoneInterest'].patchValue('0.00');
    } else {
      this.paymentForm.controls['condoneInterest'].patchValue((interest - amount).toFixed(2));
    }
  }

  onBlurLifeInsurance(event: any): void{
    let amount = parseFloat(event.target.value);
    let lifeInsurance = parseFloat(this.loanPending[this.indexPendings].lifeInsurance) - parseFloat((this.loanPending[this.indexPendings].loan.loanParameter.fortnightLifeInsurance * this.paymentForm.controls['condonFortnight'].value).toFixed(2));
    if(amount > lifeInsurance) {
      this.paymentForm.controls['lifeInsurance'].patchValue(lifeInsurance.toFixed(2));
      this.paymentForm.controls['condoneLifeInsurance'].patchValue(0);
    } else {
      this.paymentForm.controls['condoneLifeInsurance'].patchValue((lifeInsurance - amount).toFixed(2));
    }
  }

  evaluatePendingLoanValues(fortnightPending: number): void {
    console.log(`Qnas: ${fortnightPending} pending loan`, this.loanPending[this.indexPendings]);
    let capital = parseFloat(this.loanPending[this.indexPendings].amountSaving) - parseFloat((this.loanPending[this.indexPendings].loan.loanParameter.fortnightCapital * fortnightPending).toFixed(2));
    console.log(`Capital ${capital}, savings: ${this.loanPending[this.indexPendings].amountSaving} - ${(this.loanPending[this.indexPendings].loan.loanParameter.fortnightCapital * fortnightPending).toFixed(2)}`);
    let interest = (parseFloat(this.loanPending[this.indexPendings].interest) + parseFloat(this.loanPending[this.indexPendings].iva))  - parseFloat(((this.loanPending[this.indexPendings].loan.loanParameter.fortnightInterest + this.loanPending[this.indexPendings].loan.loanParameter.fortnightIVA ) * fortnightPending).toFixed(2));
    let lifeInsurance = parseFloat(this.loanPending[this.indexPendings].lifeInsurance) - parseFloat((this.loanPending[this.indexPendings].loan.loanParameter.fortnightLifeInsurance * fortnightPending).toFixed(2));
    this.balance = parseFloat((capital + interest + lifeInsurance).toString());
    this.paymentForm.controls['capital'].patchValue(capital.toFixed(2));
    this.paymentForm.controls['lifeInsurance'].patchValue(lifeInsurance.toFixed(2));
    this.paymentForm.controls['condoneLifeInsurance'].patchValue(0.00);
    this.paymentForm.controls['interest'].patchValue(interest.toFixed(2));
    this.paymentForm.controls['condoneInterest'].patchValue(0.00);
  }

  onChange(value: number): void {
    let fortnightStartArr = this.loanPending[this.indexPendings].fortnightStart.split('-');
    let fortnightEnd = this.loanPending[this.indexPendings].fortnightEnd.split('-');
    let yearStart = this.paymentForm.controls['year'].value;
    let fortnightStart = parseInt(fortnightStartArr[1]) + parseInt(value.toString());
    this.amount = 0;
    if( value > this.loanPending[this.indexPendings].fortnightPendings ) {
      value = this.loanPending[this.indexPendings].fortnightPendings;
    }

    this.evaluatePendingLoanValues(value);

    if(fortnightStart > 24) {
      this.paymentForm.controls['year'].patchValue(yearStart + 1);
      fortnightStart = fortnightStart - 24  ;
    }

    this.paymentForm.controls['fortnight'].patchValue(fortnightStart)
    this.removePayments();
    this.evaluateFortnightIncomplete();
    this.evaluateFortnightPendings(parseInt(fortnightStartArr[0]), parseInt(fortnightEnd[0]), parseInt(fortnightStartArr[1]), parseInt(fortnightEnd[1]), value)
  }

  removePayments(): void {
    while (this.payments.length !== 0) {
      this.payments.removeAt(0);
    }
  }

  EvaluateGrandTotalBalance(): void {
    let capital = parseFloat(this.loanPending.reduce((sum, current) => sum + current.amountSaving, 0).toFixed(2));
    let interest = parseFloat(this.loanPending.reduce((sum, current) => sum + current.interest + current.iva, 0).toFixed(2));
    let lifeInsurance = parseFloat(this.loanPending.reduce((sum, current) => sum + current.lifeInsurance, 0).toFixed(2));
    this.grandTotalBalance = capital + interest + lifeInsurance;
  }

  getNumberCurrentFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  isOldLoan(fortnightEnd: number): boolean {
    const currentFortnight = parseInt(`${new Date().getFullYear()}${this.formatDate(parseInt(this.getNumberCurrentFortnight()))}`);
    if(fortnightEnd > currentFortnight) {
      return false;
    }
    return true;
  }

  async showConfirmChangeFortnight( payment: PaymentInterface , fortnight: number, amount: number ) {
    const amountModified = await this.notificationService.showConfirmInput(`Seguro que desea módificar la quincena ${ fortnight } con el importe ${ amount }`, 'Modificar quincena', InputTypes.Number)
    if(amountModified !== null) {
      let amountPayments = (payment.fortnight1 + payment.fortnight2 + payment.fortnight3 + payment.fortnight4 + payment.fortnight5 + payment.fortnight6 + payment.fortnight7 + payment.fortnight8 + payment.fortnight9 + payment.fortnight10
        + payment.fortnight11 + payment.fortnight12 + payment.fortnight13 + payment.fortnight14 + payment.fortnight15 + payment.fortnight16 + payment.fortnight17 + payment.fortnight18 + payment.fortnight19 + payment.fortnight20
        + payment.fortnight21 + payment.fortnight22 + payment.fortnight23 + payment.fortnight24 + parseFloat(amountModified)) - amount;
        if(parseFloat(amountPayments.toFixed(2)) > parseFloat(payment.amount.toFixed(2))) {
          this.notificationService.showErrorMessage(`El importe total no debe ser mayor a ${this.currencyPipe.transform(payment.amount)}`);
        } else {
          this.setFortnight(payment, fortnight, parseFloat(amountModified));
          if(parseFloat(amountPayments.toFixed(2)) < parseFloat(payment.amount.toFixed(2))) {
            this.notificationService.showErrorMessage(`El importe total del prestamo ${payment.loan.loanNumber} debe ser ${this.currencyPipe.transform(payment.amount)} y actualmente tiene registrado ${this.currencyPipe.transform(amountPayments)}`);
          }
        }
    }
  }

  async showConfirmChangeFortnightSaving( saving: SavingInterface , fortnight: number, amount: number ) {
    const amountModified = await this.notificationService.showConfirmInput(`Seguro que desea módificar la quincena ${ fortnight } con el importe ${ amount }`, 'Modificar quincena', InputTypes.Number)
    if(amountModified !== null) {
      let amountSavings = (saving.fortnight1 + saving.fortnight2 + saving.fortnight3 + saving.fortnight4 + saving.fortnight5 + saving.fortnight6 + saving.fortnight7 + saving.fortnight8 + saving.fortnight9 + saving.fortnight10
        + saving.fortnight11 + saving.fortnight12 + saving.fortnight13 + saving.fortnight14 + saving.fortnight15 + saving.fortnight16 + saving.fortnight17 + saving.fortnight18 + saving.fortnight19 + saving.fortnight20
        + saving.fortnight21 + saving.fortnight22 + saving.fortnight23 + saving.fortnight24 + parseFloat(amountModified)) - amount;
        if(parseFloat(amountSavings.toFixed(2)) > parseFloat(saving.amount.toFixed(2))) {
          this.notificationService.showErrorMessage(`El importe total no debe ser mayor a ${this.currencyPipe.transform(saving.amount)}`);
        } else {
          this.setFortnightSaving(saving, fortnight, parseFloat(amountModified));
          if(parseFloat(amountSavings.toFixed(2)) < parseFloat(saving.amount.toFixed(2))) {
            this.notificationService.showErrorMessage(`El importe total de la aportación debe ser ${this.currencyPipe.transform(saving.amount)} y actualmente tiene registrado ${this.currencyPipe.transform(amountSavings)}`);
          }
        }
    }
  }

  setFortnight( payment: PaymentInterface, fortnight: number, amount: number ): void {
    switch ( fortnight ) {
      case 1:
        payment.fortnight1 = amount;
        break;
      case 2:
        payment.fortnight2 = amount;
        break;
      case 3:
        payment.fortnight3 = amount;
        break;
      case 4:
        payment.fortnight4 = amount;
        break;
      case 5:
        payment.fortnight5 = amount;
        break;
      case 6:
        payment.fortnight6 = amount;
        break;
      case 7:
        payment.fortnight7 = amount;
        break;
      case 8:
        payment.fortnight8 = amount;
        break;
      case 9:
        payment.fortnight9 = amount;
        break;
      case 10:
        payment.fortnight10 = amount;
        break;
      case 11:
        payment.fortnight11 = amount;
        break;
      case 12:
        payment.fortnight12 = amount;
        break;
      case 13:
        payment.fortnight13 = amount;
        break;
      case 14:
        payment.fortnight14 = amount;
        break;
      case 15:
        payment.fortnight15 = amount;
        break;
      case 16:
        payment.fortnight16 = amount;
        break;
      case 17:
        payment.fortnight17 = amount;
        break;
      case 18:
        payment.fortnight18 = amount;
        break;
      case 19:
        payment.fortnight19 = amount;
        break;
      case 20:
        payment.fortnight20 = amount;
        break;
      case 21:
        payment.fortnight21 = amount;
        break;
      case 22:
        payment.fortnight22 = amount;
        break;
      case 23:
        payment.fortnight23 = amount;
        break;
      case 24:
        payment.fortnight24 = amount;
        break;
    }
  }

  setFortnightSaving( saving: SavingInterface, fortnight: number, amount: number ): void {
    switch ( fortnight ) {
      case 1:
        saving.fortnight1 = amount;
        break;
      case 2:
        saving.fortnight2 = amount;
        break;
      case 3:
        saving.fortnight3 = amount;
        break;
      case 4:
        saving.fortnight4 = amount;
        break;
      case 5:
        saving.fortnight5 = amount;
        break;
      case 6:
        saving.fortnight6 = amount;
        break;
      case 7:
        saving.fortnight7 = amount;
        break;
      case 8:
        saving.fortnight8 = amount;
        break;
      case 9:
        saving.fortnight9 = amount;
        break;
      case 10:
        saving.fortnight10 = amount;
        break;
      case 11:
        saving.fortnight11 = amount;
        break;
      case 12:
        saving.fortnight12 = amount;
        break;
      case 13:
        saving.fortnight13 = amount;
        break;
      case 14:
        saving.fortnight14 = amount;
        break;
      case 15:
        saving.fortnight15 = amount;
        break;
      case 16:
        saving.fortnight16 = amount;
        break;
      case 17:
        saving.fortnight17 = amount;
        break;
      case 18:
        saving.fortnight18 = amount;
        break;
      case 19:
        saving.fortnight19 = amount;
        break;
      case 20:
        saving.fortnight20 = amount;
        break;
      case 21:
        saving.fortnight21 = amount;
        break;
      case 22:
        saving.fortnight22 = amount;
        break;
      case 23:
        saving.fortnight23 = amount;
        break;
      case 24:
        saving.fortnight24 = amount;
        break;
    }
  }

  stringify(obj) {
    let cache = [];
    let str = JSON.stringify(obj, function(key, value) {
      if(typeof value === 'object' && value !== null) {
        if(cache.indexOf(value) !== -1) {
          return;
        }
        cache.push(value);
      }
      return value;
    });
    cache = null;
    return str;
  }

  deleteFortnight(index: number): void {
    this.payments.removeAt(index);
    this.evaluateLoanValues();
  }

  evaluateLoanValues(): void {
    this.amount = 0;
    let capital = 0;
    let interest = 0;
    let lifeinsurance = 0;
    const fortnightCapital = parseFloat(this.loanPending[this.indexPendings].fortnightCapital);
    const fortnightInterest = parseFloat(this.loanPending[this.indexPendings].fortnightIVA) + parseFloat(this.loanPending[this.indexPendings].fortnightInterest);
    const fortnightLifeinsurance = parseFloat(this.loanPending[this.indexPendings].fortnightLifeinsurance)


    for (let index = 0; index < this.payments.length; index++) {
      const element = this.payments.at(index) as UntypedFormGroup;
      let amountPayment = parseFloat(this.loanPending[this.indexPendings].amount);
      const paymentTemp = parseFloat(element.controls['amount'].value)

      let depositFortnight = this.getDepositAmount(`${element.controls['year'].value}-${this.formatDate(parseInt(element.controls['fortnight'].value))}`);

      if(paymentTemp < 0 || paymentTemp > parseFloat((amountPayment - depositFortnight).toFixed(2))) {
        element.controls['amount'].patchValue(amountPayment);
      }

      let payment = parseFloat(element.controls['amount'].value);
      this.amount += payment;
      amountPayment = parseFloat(this.loanPending[this.indexPendings].amount)
      if( payment === amountPayment ){
        capital += fortnightCapital;
        interest += fortnightInterest;
        lifeinsurance += fortnightLifeinsurance;
      } else {
        let capitalTemp = fortnightCapital;
        let interestTemp = fortnightInterest;
        let lifeInsuranceTemp = fortnightLifeinsurance;

        capitalTemp -= (depositFortnight >= fortnightCapital) ? fortnightCapital : depositFortnight;
        depositFortnight = (depositFortnight >= fortnightCapital) ? depositFortnight - fortnightCapital : 0;
        if(depositFortnight > 0) {
          interestTemp -= (depositFortnight >= fortnightInterest) ? fortnightInterest : depositFortnight;
          depositFortnight = (depositFortnight >= fortnightInterest) ? depositFortnight - fortnightInterest : 0;
        }

        if(depositFortnight > 0) {
          lifeInsuranceTemp -= (depositFortnight >= fortnightLifeinsurance) ? fortnightLifeinsurance : depositFortnight;
        }
        capital += (payment >= capitalTemp) ? capitalTemp : payment;
        payment = (payment >= capitalTemp) ? payment - capitalTemp : 0;

        if(payment > 0) {
          interest += (payment >= interestTemp) ? interestTemp : payment;
          payment = (payment >= interestTemp) ? payment - interestTemp : 0;
        }

        if(payment > 0) {
          interest += (payment >= lifeInsuranceTemp) ? lifeInsuranceTemp : payment;
        }

      }
    }
    this.paymentForm.controls['capital'].patchValue(capital.toFixed(2));
    this.paymentForm.controls['interest'].patchValue(interest.toFixed(2));
    this.paymentForm.controls['condoneInterest'].patchValue('0.00');
    this.paymentForm.controls['lifeInsurance'].patchValue(lifeinsurance.toFixed(2));
    this.paymentForm.controls['condoneLifeInsurance'].patchValue('0.00');
  }

  onBlurAmountFortnight(): void {
    if(parseFloat(this.paymentForm.controls['amount'].value) < 0) {
      this.notificationService.showErrorMessage('El importe debe de ser mayor a 0');
      this.paymentForm.controls['amount'].patchValue(this.loanPending[this.indexPendings].amount);
    }

    if(parseFloat(this.paymentForm.controls['amount'].value) > parseFloat(this.loanPending[this.indexPendings].amount)) {
      this.notificationService.showErrorMessage(`El importe ${this.currencyPipe.transform(this.paymentForm.controls['amount'].value)} no debe de ser mayor a ${this.currencyPipe.transform(this.loanPending[this.indexPendings].amount)}`);
      this.paymentForm.controls['amount'].patchValue(this.loanPending[this.indexPendings].amount);
    }
  }

  existFortnight(year: number, fortnight: number): boolean {
    for (let index = 0; index < this.payments.length; index++) {
      const element = this.payments.at(index) as UntypedFormGroup;
      if(Number(year) === Number(element.controls['year'].value) && Number(fortnight) === Number(element.controls['fortnight'].value)) {
        this.notificationService.showErrorMessage(`La quincena ${year}-${ this.formatDate(element.controls['fortnight'].value)} ya se encuentra registrada`);
        index = this.payments.length;
      }
    }
    return false;
  }

  get payments() {
    return this.paymentForm.get('payments') as UntypedFormArray;
  }
}
