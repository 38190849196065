import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { PaymentsInterface } from '../../administration/payments/interfaces/payments.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanService } from '../../administration/loans/services/loan.service';
import { AccountService } from '../../account/login/services/account.service';
import { PaymentInterface } from '../../administration/payments/interfaces/payment.interface';
import { PaymentService } from '../../administration/payments/services/payment.service';
import { PaymentDTOInterface } from '../../administration/payments/interfaces/paymentDTO.interface';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styles: [
  ]
})
export class AddPaymentComponent implements OnInit {
  amount = 0;
  forma: UntypedFormGroup;
  message: string;
  gettingData = false;
  sendingData = false;
  years: number[] = [];
  payments: PaymentsInterface = {
    payments: []
  };
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  payment: PaymentInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    teacherId: '',
    year: new Date().getFullYear(),
    lastUpdated : new Date(),
    userIdLastUpdated: this.accountService.getUserId(),
    loanId: '00000000-0000-0000-0000-000000000000',
    fortnight1: 0,
    fortnight2: 0,
    fortnight3: 0,
    fortnight4: 0,
    fortnight5: 0,
    fortnight6: 0,
    fortnight7: 0,
    fortnight8: 0,
    fortnight9: 0,
    fortnight10: 0,
    fortnight11: 0,
    fortnight12: 0,
    fortnight13: 0,
    fortnight14: 0,
    fortnight15: 0,
    fortnight16: 0,
    fortnight17: 0,
    fortnight18: 0,
    fortnight19: 0,
    fortnight20: 0,
    fortnight21: 0,
    fortnight22: 0,
    fortnight23: 0,
    fortnight24: 0,
    amount: 0,
    type: 'Abono',
    capital:0,
    amountSaving: 0,
    condoneInterest: 0,
    financialProducts: 0,
    lifeInsurance: 0,
    condoneLifeInsurance: 0,
    wayToPay: '',
    interest: 0,
    folio: '',
    movementDate : `${ new Date().getFullYear() }-${ this.formatDate(new Date().getMonth() + 1) }-
      ${ this.formatDate(new Date().getDate()) }`,
    teacher: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      date: new Date(),
      active: true,
      lastUpdated: new Date(),
      userIdLastUpdated: this.accountService.getUserId(),
      regionId: '00000000-0000-0000-0000-000000000000',
      rfc: '',
      name: '',
      street: '',
      number: '',
      colony: '',
      zipCode: 0,
      locality: '',
      lastStatus: 'Activo',
      email: '',
      admission: '',
      applied: true,
      birthdate: '',
      contributionId: '00000000-0000-0000-0000-000000000000',
      gender: '',
      identifier: 0,
      amountSavings: 0,
      credit: 0,
      type: '',
      fortnight: '',
      clabe: '',
      curp: '',
      bankId: '00000000-0000-0000-0000-000000000000',
      member: false,
      amountSavingsFortnight: 0,
      NumberOfLoans: 0,
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      document: 0,
      documentNumber: '',
      contribution: {},
      teacherStatus: [],
      beneficiaries: [],
      teacherPhones: [],
      contributionPercentages: [],
      loans: [],
      savings: [],
      financialliquidity: [],
      payments: [],
      supports: [],
      lowStaffing: [],
      itIsSupplier: false
    },
    loan:  {
      id: '00000000-0000-0000-0000-000000000000',
      userId: this.accountService.getUserId(),
      regionId: '00000000-0000-0000-0000-000000000000',
      bankId: '00000000-0000-0000-0000-000000000000',
      teacherStatusId: '00000000-0000-0000-0000-000000000000',
      prefix: '',
      date: new Date(),
      active: true,
      drawId: '',
      teacherId: '',
      loanParameterId: '',
      individualAccount: '',
      checkNumber: '0',
      amount: 0,
      discount: 0,
      interests: 0,
      comment: '',
      loanNumber: '',
      fortnightStart: '',
      fortnightEnd: '',
      status: '',
      openingBonus: 0,
      remainingFortnightly: 0,
      warranty: 0,
      applied: false,
      haveFinancialLiquidity: false,
      account: '',
      movementType: '',
      lastUpdated: new Date,
      userIdLastUpdated: this.accountService.getUserId(),
      savingsPending: false,
      draw: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: true,
        name: 'N/A',
        openDate: new Date(),
        closeDate: new Date(),
        status: '',
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        loans: []
      },
      teacher: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: this.accountService.getUserId(),
        date: new Date(),
        active: true,
        regionId: '00000000-0000-0000-0000-000000000000',
        rfc: '',
        name: '',
        street: '',
        number: '',
        colony: '',
        zipCode: 0,
        locality: '',
        email: '',
        admission: '',
        applied: true,
        birthdate: '',
        contributionId: '00000000-0000-0000-0000-000000000000',
        gender: '',
        lastStatus: 'Activo',
        lastUpdated: new Date,
        userIdLastUpdated: this.accountService.getUserId(),
        identifier: 0,
        amountSavings: 0,
        credit: 0,
        type: '',
        fortnight: '',
        clabe: '',
        curp: '',
        bankId: '00000000-0000-0000-0000-000000000000',
        member: false,
        amountSavingsFortnight: 0,
        document: 0,
        documentNumber: '',
        NumberOfLoans: 0,
        bank: {
          id: '00000000-0000-0000-0000-000000000000',
          userId: '00000000-0000-0000-0000-000000000000',
          date: new Date(),
          active: false,
          lastUpdated: new Date(),
          userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
          name: '',
          code: ''
        },
        contribution: {},
        teacherStatus: [],
        beneficiaries: [],
        teacherPhones: [],
        contributionPercentages: [],
        loans: [],
        savings: [],
        financialliquidity: [],
        payments: [],
        supports: [],
        lowStaffing: [],
        itIsSupplier: false
      },
      region: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date,
        active: true,
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: 'N/A'
      },
      loanParameter: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        active: true,
        amount: 0,
        prefix: '',
        discount: 0,
        interests: 0,
        totalPerceptions: 0,
        saving: 0,
        guaranteedPercentage: 0,
        openingBonus: 0,
        fortnightId: '',
        fortnight: {
          id: '00000000-0000-0000-0000-000000000000',
          userId: '00000000-0000-0000-0000-000000000000',
          date: new Date(),
          active: true,
          numberFortnight: 0
        },
        fortnightCapital: 0,
        fortnightIVA: 0,
        fortnightInterest: 0,
        fortnightLifeInsurance: 0,
        iva: 0,
        lifeInsurance: 0,
        type: '',
        teachers: '',
        accountingConcept: ''
      },
      bank: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        date: new Date(),
        active: false,
        lastUpdated: new Date(),
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
        name: '',
        code: ''
      },
      teacherStatus: {
        id: '00000000-0000-0000-0000-000000000000',
        userId: this.accountService.getUserId(),
        date: new Date,
        active: true,
        name: 'Activo',
        percentageSaving: 0,
        lastUpdated: new Date,
        userIdLastUpdated: '00000000-0000-0000-0000-000000000000'
      },
      acceptanceDate: new Date(),
      protectionPremium: 0,
      block: 0,
      questions: [],
      payments: [],
      settlements: [],
      settlementsSaving: [],
      settlementsTotal: 0,
      settlementsSavingTotal: 0,
      settlementsCapital: 0,
      settlementsInterest: 0,
      settlementsLifeInsurance: 0,
      settlementsLifeCondoneLifeInsurance: 0,
      settlementsCondoneInterest: 0
    }
  };

  constructor( public accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private loanService: LoanService,
    private router: Router,
    private paymentService: PaymentService ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getYears();
    this.activatedRoute.params.subscribe(params => {
      if ( params['id'] ) {
          this.loanService.getById( params['id'] )
            .subscribe(loan => {
              this.payment.loanId = loan.id;
              this.payment.teacherId = loan.teacherId;
              this.payment.loan = loan;
              this.payment.teacher = loan.teacher;
              this.forma.controls['amount'].patchValue(this.payment.loan.discount);
              this.gettingData = false;
              console.log( this.forma.controls['payments'].value.length );
            }, error => this.setErrorMessage( error ) );
      }
    });
  }

  buildForm(): void {
    this.forma = this.formBuilder.group({
      year: [new Date().getFullYear() , [Validators.required]],
      fortnight: ['', [Validators.required]],
      yearEnd: [new Date().getFullYear() , [Validators.required]],
      fortnightEnd: ['', [Validators.required]],
      amount: ['', [Validators.required, Validators.min(0)]],
      payments: this.formBuilder.array([]),
    });
  }

  getYears(): void {
    for (let index = new Date().getFullYear() - 18; index < new Date().getFullYear() + 5; index++) {
      this.years.push(index);
    }
  }

  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  setErrorMessage( error: any ) {
    console.log(error);
    if (error && error.error) {
      this.message = error.message;
      this.gettingData = false;
      this.sendingData = false;
    }
  }

  addPayment() {
    // tslint:disable-next-line:max-line-length
    const startMonth = ( this.forma.controls['fortnight'].value.length === 1 ) ? `0${ this.forma.controls['fortnight'].value }` : this.forma.controls['fortnight'].value;
    // tslint:disable-next-line:max-line-length
    const endMonth = ( this.forma.controls['fortnightEnd'].value.length === 1 ) ? `0${ this.forma.controls['fortnightEnd'].value }` : this.forma.controls['fortnightEnd'].value;
    const startPeriod = parseFloat(this.forma.controls['year'].value + startMonth);
    const endPeriod = parseFloat(this.forma.controls['yearEnd'].value + endMonth);

    if ( startPeriod <= endPeriod ) {
      this.message = '';
      const payments = this.getNumberOfPayments();
      const amountPayments = parseFloat((this.amount + (payments * this.forma.controls['amount'].value)).toFixed(2));
      this.proccessPayments( payments );
      this.amount = amountPayments;
    } else {
      this.message = 'El periodo final no puede ser mayor al inicial.';
    }
  }

  getNumberOfPayments(): number {
    const anioStart = parseFloat(this.forma.controls['year'].value);
    const fortnightStart = parseFloat(this.forma.controls['fortnight'].value);
    const anioEnd = parseFloat(this.forma.controls['yearEnd'].value);
    const fortnightEnd = parseFloat(this.forma.controls['fortnightEnd'].value) + 1;
    const yearsDifference = anioEnd - anioStart;
    const fortnightsDifference = fortnightEnd - fortnightStart;
    if ( yearsDifference === 0 ) {
      return fortnightsDifference;
    }

    return ((yearsDifference * 24) - fortnightStart) + fortnightEnd;
  }

  proccessPayments(payments: number): void {
    this.amount = 0;
    const phonesArr = this.forma.get('payments') as UntypedFormArray;
    while (phonesArr.length !== 0) {
      phonesArr.removeAt(0);
    }
    let year = parseFloat(this.forma.controls['year'].value);
    let fortnight = parseFloat(this.forma.controls['fortnight'].value);
    for (let index = 1; index <= payments; index++) {
      const paymentsArr = this.forma.get('payments') as UntypedFormArray;
      const beneficiariesFG = this.BuildPayment( year, fortnight );
      paymentsArr.push(beneficiariesFG);
      fortnight++;
      if ( fortnight > 24 ) {
        year++;
        fortnight = 1;
      }
    }
  }

  BuildPayment( year: number, fortnight: number ) {
    return this.formBuilder.group({
      year: year,
      fortnight: fortnight,
      amount: [this.forma.controls['amount'].value, [Validators.required, Validators.min(0)]]
    });
  }

  sendForm(): void {
    this.addPaymentList();
    this.sendingData = true;
    this.paymentService.createPayment( this.payments )
      .subscribe(success => {
        this.router.navigate(['/reports/teachers-global/status', this.payment.teacherId]);
      }, error => this.setErrorMessage(error));
  }

  addPaymentList(): void {
    const paymentsArr = this.forma.get('payments') as UntypedFormArray;
    this.payments.payments = [];

    for (let index = 0; index < paymentsArr.length; index++) {
      const payment = paymentsArr.at(index) as UntypedFormGroup;

      if ( this.existYear( payment.controls['year'].value ) ) {
        // tslint:disable-next-line:max-line-length
        this.updateFortnight( parseFloat(payment.controls['year'].value), payment.controls['fortnight'].value, payment.controls['amount'].value );
      } else {
        // tslint:disable-next-line:max-line-length
        this.addElementToList( parseFloat(payment.controls['year'].value), payment.controls['fortnight'].value, payment.controls['amount'].value );
      }
    }
  }

  existYear(year: number): boolean {
    const result = this.payments.payments.filter(p => p.year === year);
    if ( result.length > 0 ) {
      return true;
    }
    return false;
  }

  updateFortnight(year: number, fortnight: number, amount: number): void {

    this.payments.payments.forEach(pay => {
      if ( pay.year === year ) {
        switch ( fortnight.toString() ) {
          case '1':
              pay.fortnight1 = amount;
            break;
          case '2':
              pay.fortnight2 = amount;
            break;
          case '3':
              pay.fortnight3 = amount;
            break;
          case '4':
              pay.fortnight4 = amount;
            break;
          case '5':
              pay.fortnight5 = amount;
            break;
          case '6':
              pay.fortnight6 = amount;
            break;
          case '7':
              pay.fortnight7 = amount;
            break;
          case '8':
              pay.fortnight8 = amount;
            break;
          case '9':
              pay.fortnight9 = amount;
            break;
          case '10':
              pay.fortnight10 = amount;
            break;
          case '11':
              pay.fortnight11 = amount;
            break;
          case '12':
              pay.fortnight12 = amount;
            break;
          case '13':
              pay.fortnight13 = amount;
            break;
          case '14':
              pay.fortnight14 = amount;
            break;
          case '15':
              pay.fortnight15 = amount;
            break;
          case '16':
              pay.fortnight16 = amount;
            break;
          case '17':
              pay.fortnight17 = amount;
            break;
          case '18':
              pay.fortnight18 = amount;
            break;
          case '19':
              pay.fortnight19 = amount;
            break;
          case '20':
              pay.fortnight20 = amount;
            break;
          case '21':
              pay.fortnight21 = amount;
            break;
          case '22':
              pay.fortnight22 = amount;
            break;
          case '23':
              pay.fortnight23 = amount;
            break;
          case '24':
              pay.fortnight24 = amount;
            break;
        }
      }
    });
  }

  addElementToList(year: number, fortnight: number, amount: number): void {
    const payment: PaymentDTOInterface = {
      userId: this.payment.userId,
      loanId: this.payment.loanId,
      teacherId: this.payment.teacherId,
      type: 'Retención',
      movementDate: '0001-01-01 00:00:00.0000000',
      folio: '',
      amountSaving: 0,
      year: year,
      amount: 0,
      interest: 0,
      fortnight1: 0,
      fortnight2: 0,
      fortnight3: 0,
      fortnight4: 0,
      fortnight5: 0,
      fortnight6: 0,
      fortnight7: 0,
      fortnight8: 0,
      fortnight9: 0,
      fortnight10: 0,
      fortnight11: 0,
      fortnight12: 0,
      fortnight13: 0,
      fortnight14: 0,
      fortnight15: 0,
      fortnight16: 0,
      fortnight17: 0,
      fortnight18: 0,
      fortnight19: 0,
      fortnight20: 0,
      fortnight21: 0,
      fortnight22: 0,
      fortnight23: 0,
      fortnight24: 0,
      capital: 0,
      condoneFortnight: 0,
      condoneInterest: 0,
      financialProducts: 0,
      lifeInsurance: 0,
      condoneLifeInsurance: 0,
      applyFortnight: '',
      settlementId: '00000000-0000-0000-0000-000000000000'
    };

    this.SetFortnightValue(payment, fortnight.toString(), amount );

    this.payments.payments.push(payment);
  }

  SetFortnightValue(payment: PaymentDTOInterface, fortnight: string, amount: number): void {
    switch ( fortnight ) {
      case '1':
        payment.fortnight1 = amount;
        break;
      case '2':
        payment.fortnight2 = amount;
        break;
      case '3':
        payment.fortnight3 = amount;
        break;
      case '4':
        payment.fortnight4 = amount;
        break;
      case '5':
        payment.fortnight5 = amount;
        break;
      case '6':
        payment.fortnight6 = amount;
        break;
      case '7':
        payment.fortnight7 = amount;
        break;
      case '8':
        payment.fortnight8 = amount;
        break;
      case '9':
        payment.fortnight9 = amount;
        break;
      case '10':
        payment.fortnight10 = amount;
        break;
      case '11':
        payment.fortnight11 = amount;
        break;
      case '12':
        payment.fortnight12 = amount;
        break;
      case '13':
        payment.fortnight13 = amount;
        break;
      case '14':
        payment.fortnight14 = amount;
        break;
      case '15':
        payment.fortnight15 = amount;
        break;
      case '16':
        payment.fortnight16 = amount;
        break;
      case '17':
        payment.fortnight17 = amount;
        break;
      case '18':
        payment.fortnight18 = amount;
        break;
      case '19':
        payment.fortnight19 = amount;
        break;
      case '20':
        payment.fortnight20 = amount;
        break;
      case '21':
        payment.fortnight21 = amount;
        break;
      case '22':
        payment.fortnight22 = amount;
        break;
      case '23':
        payment.fortnight23 = amount;
        break;
      case '24':
        payment.fortnight24 = amount;
        break;
    }
  }

}
