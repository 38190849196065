import { Component, OnInit } from '@angular/core';
import { TransferInterface } from './interfaces/transfer.interface';
import { TransferService } from './services/transfer.service';
import { CancelTransferInterface } from './interfaces/cancelTransfer.interface';
import { AccountService } from '../../account/login/services/account.service';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { ExcelService } from '../../../services/excel.service';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { InputTypes } from '../../../../app/enums/sweetalert.enum';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styles: []
})
export class TransfersComponent implements OnInit {
  isLoadingData = false;
  errorMessage: string;
  isWorking = false;
  datasource: Array<TransferInterface> = [];
  unaffiliated: Array<TransferInterface> = [];
  jsonArray: Array<TransferInterface> = [];
  applications: ApplicationInterface[];
  transferType = 'Terceros';

  constructor( private transferService: TransferService,
    public accountService: AccountService,
    private excelService: ExcelService,
    private notificationService: NotificationService,
    private helperService: HelperService ) { }

  ngOnInit() {
    this.accountService.getApplications('banks', 'transfers')
      .subscribe(applications => {
        this.accountService.getApplications('administration', 'loans')
          .subscribe(applicationLoans => {
            this.applications = applications.concat(applicationLoans);
            this.loadData( );
          },
          error => this.helperService.setErrorMessage(error));
      }, error => this.helperService.setErrorMessage(error));
  }

  handlerClick(): void {
    this.isWorking = true;
    this.transferService.post(this.accountService.getUserId(), this.datasource.filter(d => d.active === true))
      .subscribe(( data ) => {
        switch (this.transferType) {
          case 'Terceros':
            this.descargarArchivo(this.generarTextoTerceros(data), `Transferencias_Terceros_${new Date().getTime()}.txt`);
            break;
          case 'SPEI':
            this.descargarArchivo(this.generarTextoSPEI(data), `Transferencias_SPEI_${new Date().getTime()}.txt`);
            break;
        }
        this.descargarArchivo(this.generarInternalTransfer(data), `Propias_${new Date().getTime()}.txt`);
        this.loadData( );
      }, error => {
        this.helperService.setErrorMessage(error);
      });
  }

  handlerType( transferType: string) {
    this.transferType = transferType;
    this.filterData(transferType);
  }

  loadData( ) {
    this.isLoadingData = true;
    this.transferService.get( )
    .subscribe(data => {
      this.jsonArray = data;
      this.filterData(this.transferType);
      this.isLoadingData = false;
      this.isWorking = false;
    }, error => {
      this.helperService.setErrorMessage(error);
    });
  }

  filterData(transferType: string): void {
    this.datasource = this.jsonArray.filter(f => f.itIsSupplier === true && f.type === transferType);
    this.unaffiliated = this.jsonArray.filter(f => f.itIsSupplier === false && f.type === transferType);
  }

  descargarArchivo(contenidoEnBlob, nombreArchivo) {
    const reader: FileReader = new FileReader();
    reader.onload = function (event: Event) {
      const save = document.createElement('a');
      save.href = reader.result as string; // event.target.result;
      save.target = '_blank';
      save.download = nombreArchivo || 'archivo.txt';
      const clicEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      save.dispatchEvent(clicEvent);
      (window.URL).revokeObjectURL(save.href);
    };
    reader.readAsDataURL(contenidoEnBlob);
  }

  generarTextoTerceros(transfers: Array<TransferInterface>) {
    const texto = [];
    transfers.forEach(element => {
      if(element.type !== 'Traspaso') {
        // tslint:disable-next-line:max-line-length
        texto.push(`${ element.key }\t${ element.rfc }\t${ element.accountSNTE }\t'${ element.clabe }\t${ this.completeLeft(element.amount.toString(), 16, '0') }\t${ element.reference }\t ${ this.completeRight(element.description, 38, ' ')}\t${ this.completeRight(' ', 14, ' ') }\t00000000000000\t\t${ this.completeRight('x', 100, ' ') }\t0000000\n`);
      }
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  generarInternalTransfer(transfers: Array<TransferInterface> ) {
    const texto = [];
    transfers.forEach(element => {
      if(element.settlementsSavingTotal > 0) {
        texto.push(`01\t${ this.completeRight(' ', 13, ' ') }\t${ element.accountSNTE }\t'1087275480\t${ this.completeLeft(element.settlementsSavingTotal.toFixed(2).toString(), 16, '0') }\t${ element.reference }\t ${ this.completeRight(`AHORRO ${element.rfc}`, 38, ' ')}\t${ this.completeRight(' ', 14, ' ') }\t00000000000000\t\t${ this.completeRight('x', 100, ' ') }\t0000000\n`);
      }

      if(element.settlementsTotal > 0) {
        texto.push(`01\t${ this.completeRight(' ', 13, ' ') }\t${ element.accountSNTE }\t'1087275480\t${ this.completeLeft(element.settlementsTotal.toFixed(2).toString(), 16, '0') }\t${ element.reference }\t ${ this.completeRight(`ADEUDO ${element.rfc}`, 38, ' ')}\t${ this.completeRight(' ', 14, ' ') }\t00000000000000\t\t${ this.completeRight('x', 100, ' ') }\t0000000\n`);
      }
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  generarTextoSPEI(transfers: Array<TransferInterface>) {
    const texto = [];
    transfers.forEach(element => {
      // tslint:disable-next-line:max-line-length
      texto.push(`${ element.key }\t${ element.rfc }\t${ element.accountSNTE }\t'${ element.clabe }\t${ this.completeLeft(element.amount.toString(), 16, '0') }\t${ element.reference }\t ${ this.completeRight(element.description, 39, ' ')}\t${ element.rfcsnte }\t00000000000000\t\t ${ this.completeRight(element.description, 99, ' ') }\t0000000\n`);
    });
    return new Blob(texto, {
      type: 'text/plain'
    });
  }

  completeLeft(text: string, numberCharacters: number, character: string): string {
    let textReturned = '';
    for (let index = text.length; index < numberCharacters; index++) {
      textReturned = `${ character }${ textReturned }`;
    }
    return textReturned + text;
  }

  completeRight(text: string, numberCharacters: number, character: string): string {
    for (let index = text.length; index < numberCharacters; index++) {
      text = `${ text }${ character }`;
    }
    return text;
  }

  async showCancelConfirm(id: string, rfc: string, name: string) {
    const comment = await this.notificationService.showConfirmInput(`¿Seguro que desea cancelar la transferencia ${ rfc } ( ${ name } )?`, 'Cancelación de transferencias', InputTypes.Textarea );
    if(comment !== null) {
      const transfer: CancelTransferInterface = {
        loanId: id,
        userId: this.accountService.getUserId(),
        comment: comment
      };
      this.deleteItem(transfer);
    }
  }

  deleteItem(transfer: CancelTransferInterface) {
    this.transferService.update(transfer).subscribe(
      () => {
        this.loadData( );
        this.notificationService.toastConfirm('Se cancelo correctamente la transferencia');
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  getExcel() {
    const datos = this.jsonArray
    .map( function(item) {
      return {
        'RFC': item.rfc,
        'Nombre': item.name,
        '# Hoja': item.loanNumber,
        'Importe': item.amount,
        '# Quincena': item.numberFortnight,
        'Qna Inicio': item.fortnightStart,
        'Qna Final': item.fortnightEnd,
        'Región': item.region,
        'Teléfono': item.phone,
        'Cuenta': item.clabe,
        'Banco': item.bankDescription
      };
    } );
    this.excelService.exportAsExcelFile(datos,
      `Transferencias`);
  }

  handlerChecbox(checked: boolean, item: any): void {
    item.active = checked;
  }

  dataValid(): boolean {
    return this.datasource.some( l => l.active === true );
  }

  handlerSelectAll( value: boolean): void {
    this.datasource.forEach(element => {
      element.active = value
    });
  }
}
