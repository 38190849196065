<div class="content">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Administración</li>
        <li class="breadcrumb-item"><a [routerLink]="[returnPath, searchValue]" routerLinkActive="router-link-active">Prestamos</a></li>
    </ol>
  </nav>

  <div class="title-search-block">
    <div class="title-block">
        <div class="row">
            <div class="col-md-6">
                <h3 class="title">
                    <h3 class="title">Detalle</h3>
                </h3>
            </div>
        </div>
    </div>
  </div>

  <app-loading-data *ngIf="LoadingData"></app-loading-data>

  <div class="card card-block animated fadeIn fast">

    <div class="row">
      <div class="col-md-12 text-center">
        <h4 class="info"><b>{{ loan.teacher.name | uppercase }} <small>({{loan.teacher.rfc | uppercase}})</small></b>
        </h4>
        <hr>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label"><i class="fas fa-asterisk required"></i> Prefijo</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.loanNumber.split('-')[0]}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label"><i class="fas fa-asterisk required"></i> # Hoja</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.loanNumber.split('-')[1]}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label">Sorteo</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.draw.name}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label">Depósito</label>
          <input type="text" class="form-control underlined" readonly value="{{deposit|currency}}">
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label"><i class="fas fa-asterisk required"></i>  Monto / Quincenas</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.amount + ' / ' + loan.loanParameter.fortnight.numberFortnight}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label">Interes</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.loanParameter.interests|currency}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label">Descuento</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.loanParameter.discount|currency}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label"><i class="fas fa-asterisk required"></i> Región</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.region.name}}">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Inicial</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.fortnightStart}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label"><i class="fas fa-asterisk required"></i> Qna. Final</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.fortnightEnd}}">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label class="control-label"><i class="fas fa-asterisk required"></i> Tipo de Movimiento</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.movementType}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="loan.bank">
        <div class="form-group">
          <label class="control-label">Banco</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.bank.name}}">
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" *ngIf="loan.bank">
        <div class="form-group">
          <label class="control-label"><i class="fas fa-asterisk required"></i> CLABE</label>
          <input type="text" class="form-control underlined" readonly value="{{loan.account}}">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="form-group">
          <label class="control-label">Comentario</label>
          <input type="text" class="form-control underlined" readonly maxlength="200" value="{{loan.comment}}">
        </div>
      </div>
    </div>

    <fieldset *ngIf="loansSettlements?.length > 0">
      <div class="row">
        <div class="col-md-12">
          <legend>Prestamos ( {{ loansSettlements?.length }} )</legend>
        </div>
      </div>

      <div class="accordion" id="accordionExample">
        <div class="card" *ngFor="let item of loansSettlements; let i = index">
          <div class="card-header" [id]="'heading' + i">
            <h5 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + i"
                aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                Monto: {{ item.amount | currency }} # Hoja: {{ item.loanNumber }} # Cheque /
                Transferencia: {{ item.checkNumber }}<br>Qna Inicial: {{ item.fortnightStart }} Qna Final: {{
                item.fortnightEnd }} Estatus: {{ item.status }}
                Fecha de aplicación {{ item.acceptanceDate | date:'dd/MM/yyyy' }}
                <span class="text-danger"><b>Quincenas aplicadas {{item.payments[0].condoneFortnight}}</b></span>
              </button>
            </h5>
          </div>

          <div [id]="'collapse' + i " class="collapse show" [attr.aria-labelledby]="'heading' + i" data-parent="#accordionExample">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                      <tr>
                          <th scope="col">Año</th>
                          <th scope="col">Qna 1<br />Qna 13</th>
                          <th scope="col">Qna 2<br />Qna 14</th>
                          <th scope="col">Qna 3<br />Qna 15</th>
                          <th scope="col">Qna 4<br />Qna 16</th>
                          <th scope="col">Qna 5<br />Qna 17</th>
                          <th scope="col">Qna 6<br />Qna 18</th>
                          <th scope="col">Qna 7<br />Qna 19</th>
                          <th scope="col">Qna 8<br />Qna 20</th>
                          <th scope="col">Qna 9<br />Qna 21</th>
                          <th scope="col">Qna 10<br />Qna 22</th>
                          <th scope="col">Qna 11<br />Qna 23</th>
                          <th scope="col">Qna 12<br />Qna 24</th>
                          <th scope="col">Subtotal</th>
                      </tr>
                  </thead>

                  <tbody>
                      <tr class="text-right" *ngFor="let payment of item.payments; let i = index">
                          <td>{{ payment.year }}</td>
                          <td>{{ payment.fortnight1 | currency }}
                              <br />
                              {{ payment.fortnight13 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight2 | currency }}
                            <br />
                            {{ payment.fortnight14 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight3 | currency }}
                            <br />
                            {{ payment.fortnight15 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight4 | currency }}
                            <br />
                            {{ payment.fortnight16 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight5 | currency }}
                            <br />
                            {{ payment.fortnight17 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight6 | currency }}
                            <br />
                            {{ payment.fortnight18 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight7 | currency }}
                            <br />
                            {{ payment.fortnight19 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight8 | currency }}
                            <br />
                            {{ payment.fortnight20 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight9 | currency }}
                            <br />
                            {{ payment.fortnight21 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight10 | currency }}
                            <br />
                            {{ payment.fortnight22 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight11 | currency }}
                            <br />
                            {{ payment.fortnight23 | currency }}
                          </td>
                          <td>
                            {{ payment.fortnight12 | currency }}
                            <br />
                            {{ payment.fortnight24 | currency }}
                          </td>
                          <td>
                            {{ payment.amount | currency }}
                            <br />
                            {{ payment.type }}
                          </td>
                      </tr>
                  </tbody>
                  <tfoot>
                    <tr *ngIf="item.settlementsCondoneInterest === 0 && item.settlementsLifeCondoneLifeInsurance === 0">
                      <td colspan="11" >

                      </td>
                      <td colspan="2">
                        <b *ngIf="item.settlementsCondoneInterest === 0 && item.settlementsLifeCondoneLifeInsurance === 0" >Total</b>
                      </td>
                      <td class="text-right" >
                        <b>{{item.totalPayments| currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="item.settlementsCondoneInterest > 0 && item.settlementsLifeCondoneLifeInsurance > 0">
                      <td colspan="11" >

                      </td>
                      <td colspan="2">
                        <span >Subtotal</span>
                      </td>
                      <td class="text-right" >
                        {{item.totalPayments| currency}}
                      </td>
                    </tr>
                    <tr *ngIf="item.settlementsCondoneInterest > 0">
                      <td colspan="11" >

                      </td>
                      <td colspan="2">
                        <span class="text-danger">Interes Condonado</span>
                      </td>
                      <td class="text-right">
                        <span class="text-danger">{{item.settlementsCondoneInterest | currency}}</span>
                      </td>
                    </tr>
                    <tr *ngIf="item.settlementsLifeCondoneLifeInsurance > 0">
                      <td colspan="11" >
                      </td>
                      <td colspan="2">
                        <span class="text-danger">Seguro Condonado</span>
                      </td>
                      <td class="text-right">
                        <span class="text-danger">{{item.settlementsLifeCondoneLifeInsurance | currency}}</span>
                      </td>
                    </tr>
                    <tr *ngIf="item.settlementsCondoneInterest > 0 || item.settlementsLifeCondoneLifeInsurance > 0">
                      <td colspan="11" >
                      </td>
                      <td colspan="2">
                        <b>Total</b>
                      </td>
                      <td class="text-right">
                        <b>{{item.totalPayments - (item.settlementsCondoneInterest + item.settlementsLifeCondoneLifeInsurance)| currency}}</b>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="loan.settlementsSaving?.length > 0">
      <div class="row">
        <div class="col-md-2">
          <legend>Ahorros ( {{ loan.settlementsSaving?.length }} )</legend>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                  <tr>
                      <th scope="col">Año</th>
                      <th scope="col">Qna 1<br />Qna 13</th>
                      <th scope="col">Qna 2<br />Qna 14</th>
                      <th scope="col">Qna 3<br />Qna 15</th>
                      <th scope="col">Qna 4<br />Qna 16</th>
                      <th scope="col">Qna 5<br />Qna 17</th>
                      <th scope="col">Qna 6<br />Qna 18</th>
                      <th scope="col">Qna 7<br />Qna 19</th>
                      <th scope="col">Qna 8<br />Qna 20</th>
                      <th scope="col">Qna 9<br />Qna 21</th>
                      <th scope="col">Qna 10<br />Qna 22</th>
                      <th scope="col">Qna 11<br />Qna 23</th>
                      <th scope="col">Qna 12<br />Qna 24</th>
                      <th scope="col">Subtotal</th>
                  </tr>
              </thead>

              <tbody>
                  <tr class="text-right" *ngFor="let saving of loan.settlementsSaving; let i = index">
                      <td>{{ saving.year }}</td>
                      <td>{{ saving.fortnight1 | currency }}
                          <br />
                          {{ saving.fortnight13 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight2 | currency }}
                        <br />
                        {{ saving.fortnight14 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight3 | currency }}
                        <br />
                        {{ saving.fortnight15 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight4 | currency }}
                        <br />
                        {{ saving.fortnight16 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight5 | currency }}
                        <br />
                        {{ saving.fortnight17 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight6 | currency }}
                        <br />
                        {{ saving.fortnight18 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight7 | currency }}
                        <br />
                        {{ saving.fortnight19 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight8 | currency }}
                        <br />
                        {{ saving.fortnight20 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight9 | currency }}
                        <br />
                        {{ saving.fortnight21 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight10 | currency }}
                        <br />
                        {{ saving.fortnight22 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight11 | currency }}
                        <br />
                        {{ saving.fortnight23 | currency }}
                      </td>
                      <td>
                        {{ saving.fortnight12 | currency }}
                        <br />
                        {{ saving.fortnight24 | currency }}
                      </td>
                      <td>
                        {{ saving.amount | currency }}
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </fieldset>

    <div class="row">
      <div class="col-12 text-center">
        <button class="btn btn-outline-primary mr-2" (click)="showConfirmValidation()" *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Validar') && loan.status === 'Temporal'" >
          <i class="fas fa-check"></i> Validar
        </button>

        <a class="btn btn-outline-warning" [routerLink]="['/administration/loans/edit', loan.id, '/administration/loans/details/' + loan.id]" *ngIf="accountService.havePermission(applications, 'Prestamos Refinanciamiento Editar') && loan.status !== 'Validado'" >
          <i class="far fa-edit"></i> Editar
        </a>
      </div>
    </div>

    <app-error-message *ngIf="message" [message]="message"></app-error-message>

    <div>
      <a class="btn btn-outline-info " [routerLink]="[returnPath, searchValue] "><i class="fas fa-arrow-left "></i> Regresar</a>
    </div>

  </div>

</div>

<ng-template appOpen></ng-template>
